import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import SOFMoudle from '../SOFDownload';
import {
    fetchGetAnswer,
    fetchGetAllO1,
    fetchCheckFinishSOF,
    fetchGetAllO2,
    fetchSubmitAnswer,
    fetchFinishSOF,
} from '../../../services/SOFServices';
import { UserContext } from '../../../core/user';
import VerticalNavbar from '../../../components/navbar/SOFNavbar';


const ODownload = () => {
    const { userState } = useContext(UserContext);
    const { o1oro2, oid } = useParams();

    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const toggleNavbar = () => {
        setIsNavExpanded(!isNavExpanded);
    };
    const type = 'o';
    const [step, setStep] = useState(oid-1);
    const [loading, setLoading] = useState(true);
    const [o, setO] = useState([]);
    const [finishO1, setFinishO1] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [isO1, setIsO1] = useState(true);

    useEffect(() => {
        const checkFinishAndFetchData = async () => {
            try {
                if (o1oro2 == '2' || o1oro2 == 2) {
                    const o2Response = await fetchGetAllO2();
                    if (o2Response.ok) {
                        const o2Data = await o2Response.json();
                        setO(o2Data);
                        setLoading(false);
                        setIsO1(false);
                        console.log('o2', o2Data);
                    }
                } else {
                    const o1Response = await fetchGetAllO1();
                    if (o1Response.ok) {
                        const o1Data = await o1Response.json();
                        setO(o1Data);
                        setLoading(false);
                        setIsO1(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        checkFinishAndFetchData();
    }, [userState.uid]);

    useEffect(() => {
        const fetchAnswer = async () => {
            if (o.length > 0 && step < o.length) {
                try {
                    const currentStep = o[step];
                    const response2 = await fetchGetAnswer(
                        userState.uid,
                        isO1 ? 'o1' : 'o2',
                        isO1 ? currentStep.o1id : currentStep.o2id,
                    );
                    if (response2.ok) {
                        const data = await response2.json();
                        setAnswer(data.answer);
                    }
                } catch (error) {
                    const currentStep = o[step];
                    if (currentStep.type === 'letter') {
                        setAnswer(new Array(8).fill(''));
                    } else if (currentStep.type === 'feedback') {
                        setAnswer(new Array(3).fill(''));
                    } else if (currentStep.type === 'quiz12') {
                        setAnswer(new Array(4).fill(''));
                    } else if (currentStep.quiz.length > 0) {
                        setAnswer(
                            currentStep.quiz[0] === ''
                                ? []
                                : new Array(currentStep.quiz.length).fill(''),
                        );
                    }
                }
            }
        };

        fetchAnswer();
    }, [o, step, userState.uid, isO1]);

    const handleSubmitAnswer = () => {
        const answerinfo = isO1
            ? { uid: userState.uid, o1id: o[step].o1id, answer }
            : { uid: userState.uid, o2id: o[step].o2id, answer };

        fetchSubmitAnswer(answerinfo, userState.uid, isO1 ? 'o1' : 'o2');

        if (o[step].type === 'letter') {
            const answerinfo = {
                uid: userState.uid,
                o1id: o[step].o1id + 1,
                answer,
            };
            fetchSubmitAnswer(answerinfo, userState.uid, 'o1');
        }
    };

    const finishSOF = () => {
        fetchFinishSOF(isO1 ? 'o1' : 'o2', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log(data);
                });
            }
        });
    };

    return (
        <div className="flex flex-grow">
            <aoide className={`transition-width duration-300 `}>
                <VerticalNavbar
                    isExpanded={isNavExpanded}
                    toggleNavbar={toggleNavbar}
                    setStep={setStep}
                    type={type}
                    isFirst={isO1}
                    setIsFirst={setIsO1}
                    setSOF={setO}
                    userState={userState}
                />
            </aoide>
            <main className="flex-grow p-2 overfslow-auto">
                <div className="flex flex-col w-full h-full space-y-2">
                    <div className="flex flex-col justify-start w-full h-full flex-grow">
                        {o.length > 0 ? (
                            <SOFMoudle
                                step={step}
                                doc={o[step]}
                                answer={answer}
                                setAnswer={setAnswer}
                                finishSOF={finishSOF}
                            />
                        ) : null}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ODownload;
