import AppConfig from '../core/config';

import { fetchRefresh } from './AuthServices';

const fetchGetAllSOFWithAnswer = async (sof) => {
    const url = `${AppConfig.api_base_url}/${sof}/getAllSOFWithAnswer`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }
    
    return response;
}

const fetchGetSOFWithAnswer = async (sof, uid) => {
    const url = `${AppConfig.api_base_url}/${sof}/getSOFWithAnswer/${uid}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }
    
    return response;
}    

const fetchGetMenu = async (sof) => {
    const url = `${AppConfig.api_base_url}/sof/menu/${sof}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }
    
    return response;
}

const fetchGetAnswer = async (uid, sof, s1id) => {
    const url = `${AppConfig.api_base_url}/${sof}/getAnswer/${uid}/${s1id}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;

}

const fetchCheckMaxStep = async (uid, sof) => {
    const url = `${AppConfig.api_base_url}/${sof}/maxStepCheck/${uid}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();
        
        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchSubmitAnswer = async (answer, uid, sof) => {
    const url = `${AppConfig.api_base_url}/${sof}/submit/${uid}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(answer),
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(answer),
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchFinishSOF = async (sof, uid) => {
    const url = `${AppConfig.api_base_url}/sof/finish_${sof}/${uid}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ uid }),
            });

            return retryResponse;
        }
        
    }

    return response;
}


const fetchCheckFinishSOF = async (sof, uid) => {
    const url = `${AppConfig.api_base_url}/sof/check_${sof}/${uid}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',

            });

            return retryResponse;
        }
    }

    return response;
}

const fetchGetAllS1 = async () => {
    const url = `${AppConfig.api_base_url}/s1/all`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchGetAllS2 = async () => {
    const url = `${AppConfig.api_base_url}/s2/all`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchGetAllO1 = async () => {
    const url = `${AppConfig.api_base_url}/o1/all`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchGetAllO2 = async () => {
    const url = `${AppConfig.api_base_url}/o2/all`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchGetAllF1 = async () => {
    const url = `${AppConfig.api_base_url}/f1/all`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchGetAllF2 = async () => {
    const url = `${AppConfig.api_base_url}/f2/all`;
    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchAllCheckSOF = async (uid) => {
    const url = `${AppConfig.api_base_url}/sof/check_all/${uid}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchUpdateSOF = async (sof, sofModule) =>{
    const url = `${AppConfig.api_base_url}/${sof}/updateSOF`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(sofModule),
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();
        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(sofModule),
            });

            return retryResponse;
        }
    }

    return response;
}

export {fetchCheckMaxStep, fetchUpdateSOF, fetchGetAllSOFWithAnswer, fetchGetMenu, fetchGetSOFWithAnswer, fetchGetAnswer, fetchFinishSOF, fetchCheckFinishSOF, fetchGetAllS1, fetchGetAllS2, fetchGetAllO1, fetchGetAllO2, fetchGetAllF1, fetchGetAllF2, fetchAllCheckSOF, fetchSubmitAnswer }