import React, { useState, useEffect, useContext } from 'react';
import SOFMoudle from '../SOFModule';
import {
    fetchGetAnswer,
    fetchGetAllS1,
    fetchCheckFinishSOF,
    fetchGetAllS2,
    fetchSubmitAnswer,
    fetchFinishSOF,
    fetchCheckMaxStep,
} from '../../../services/SOFServices';
import { UserContext } from '../../../core/user';
import VerticalNavbar from '../../../components/navbar/SOFNavbar';

const S = () => {
    const { userState } = useContext(UserContext);

    const [isNavExpanded, setIsNavExpanded] = useState(true);
    const [canGoToMenu, setCanGoToMenu] = useState([1, 1]);

    const toggleNavbar = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    const handleFetchNavbar = () => {
        fetchCheckMaxStep(userState.uid, `${type}1`).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    if (data === -1) {
                        fetchCheckMaxStep(userState.uid, `${type}2`).then(
                            (response) => {
                                if (response.ok) {
                                    response.json().then((data) => {
                                        if (data === -1) {
                                            setCanGoToMenu([3, 12]);
                                        } else {
                                            setCanGoToMenu([2, data]);
                                        }
                                    });
                                }
                            },
                        );
                    } else {
                        setCanGoToMenu([1, data]);
                    }
                });
            }
        });
    };

    const type = 's';
    const [step, setStep] = useState(
        parseInt(userState.sof == 'feedback' ? 0 : userState.sof, 10),
    );
    const [loading, setLoading] = useState(true);
    const [s, setS] = useState([]);
    const [finishS1, setFinishS1] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [isS1, setIsS1] = useState(true);

    useEffect(() => {
        if (!userState || !userState.sof) {
            return; // userState가 준비되지 않았으면 로직을 실행하지 않음
        }

        handleFetchNavbar();

        const checkFinishAndFetchData = async () => {
            try {
                const response = await fetchCheckFinishSOF('s1', userState.uid);
                if (response.ok) {
                    const data = await response.json();
                    setFinishS1(data);

                    if (data) {
                        const s2Response = await fetchGetAllS2();
                        if (s2Response.ok) {
                            const s2Data = await s2Response.json();
                            setS(s2Data);
                            setLoading(false);
                            setIsS1(false);
                            console.log('s2', s2Data);
                        }
                    } else {
                        const s1Response = await fetchGetAllS1();
                        if (s1Response.ok) {
                            const s1Data = await s1Response.json();
                            setS(s1Data);
                            setLoading(false);
                            setIsS1(true);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        checkFinishAndFetchData();
    }, [userState.uid]);

    useEffect(() => {
        const fetchAnswer = async () => {
            if (s.length > 0 && step < s.length) {
                try {
                    const currentStep = s[step];
                    const response2 = await fetchGetAnswer(
                        userState.uid,
                        isS1 ? 's1' : 's2',
                        isS1 ? currentStep.s1id : currentStep.s2id,
                    );
                    if (response2.ok) {
                        const data = await response2.json();
                        setAnswer(data.answer);
                    }
                } catch (error) {
                    const currentStep = s[step];
                    if (currentStep.type === 'letter') {
                        setAnswer(new Array(8).fill(''));
                    } else if (currentStep.type === 'feedback') {
                        setAnswer(new Array(3).fill(''));
                    } else if (currentStep.type === 'quiz12') {
                        setAnswer(new Array(4).fill(''));
                    } else if (currentStep.quiz.length > 0) {
                        setAnswer(
                            currentStep.quiz[0] === ''
                                ? []
                                : new Array(currentStep.quiz.length).fill(''),
                        );
                    }
                }
            }
        };

        fetchAnswer();
    }, [s, step, userState.uid, isS1]);

    const handleSubmitAnswer = () => {
        const answerinfo = isS1
            ? { uid: userState.uid, s1id: s[step].s1id, answer }
            : { uid: userState.uid, s2id: s[step].s2id, answer };

        fetchSubmitAnswer(answerinfo, userState.uid, isS1 ? 's1' : 's2');

        if (s[step].type === 'letter') {
            const answerinfo = {
                uid: userState.uid,
                s1id: s[step].s1id + 1,
                answer,
            };
            fetchSubmitAnswer(answerinfo, userState.uid, 's1');
        }
        handleFetchNavbar();
    };

    const finishSOF = () => {
        fetchFinishSOF(isS1 ? 's1' : 's2', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log(data);
                });
            }
        });
    };

    return (
        <div className="flex flex-grow">
            <aside className={`transition-width duration-300 `}>
                <VerticalNavbar
                    isExpanded={isNavExpanded}
                    toggleNavbar={toggleNavbar}
                    setStep={setStep}
                    type={type}
                    isFirst={isS1}
                    setIsFirst={setIsS1}
                    setSOF={setS}
                    userState={userState}
                    canGoToMenu={canGoToMenu}
                />
            </aside>
            <main className="flex-grow p-2 overfslow-auto">
                <div className="flex flex-col w-full h-full space-y-2">
                    <div className="flex flex-col justify-start w-full h-full flex-grow">
                        {s.length > 0 ? (
                            <SOFMoudle
                                step={step}
                                sof={s}
                                setStep={setStep}
                                doc={s[step]}
                                setSOF={setS}
                                answer={answer}
                                setAnswer={setAnswer}
                                finishSOF={finishSOF}
                                setIsFirst={setIsS1}
                                isFirst={isS1}
                                fetchGetAllSecond={fetchGetAllS2}
                                handleSubmitAnswer={handleSubmitAnswer}
                                type={type}
                            />
                        ) : null}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default S;
