import React from 'react';
import Typography from '@mui/material/Typography';

import Letter2Background from '../../../assets/images/sofimage/letter2Background.png';

const Letter2 = ({handleInputChange, answer}) => {
  return (
    <div 
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${Letter2Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '40px',
        width: '80%',
        margin: '20px auto',
        fontFamily: 'cursive',
      }}
    >
        <div style={{ alignSelf: 'flex-start', marginBottom: '20px' }}>
        <Typography>
            사랑하는 <input type="text" style={{ border: '1px solid #000' }} onChange={(event) => handleInputChange(0, event)} value={answer[0]} /> 에게
        </Typography>
        </div>

        <Typography variant="body1" style={{ marginBottom: '20px', width: '80%' }}>
            <textarea 
            style={{
                width: '90%', 
                height: '200px', 
                fontFamily: 'inherit', 
                border: '1px solid #000', 
                padding: '10px', 
                boxSizing: 'border-box', 
                resize: 'none',  // 크기 조절 막기
                outline: 'none' // 포커스 시 외곽선 제거
            }} 
            onChange={(event) => handleInputChange(1, event)}
            value={answer[1]}
            />
        </Typography>

        <Typography variant="h6" style={{ textAlign: 'right', width: '100%' }} >
            너의 영원한 친구로부터
        </Typography>
    </div>
  );
};

export default Letter2;
