import React from 'react';
import Box1 from './Box1';
import Typography from '@mui/material/Typography';


// 파싱된 텍스트 컴포넌트
export default function Quiz2Module ({ quiz, handleInputChange, answer }) {
    return (
        <>
            <Box1 content={quiz.map((q, index) => {
                return (
                    <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <Typography>{q}</Typography>
                        <input
                            style={{ flex: '1', backgroundColor: '', border: '1px solid #000' }}
                            type="text"
                            onChange={(event) => handleInputChange(index, event)}
                            value={answer[index]}
                        />
                    </div>
                );
            })} />
        </>
    )
};