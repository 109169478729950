import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button, Typography, styled, Divider } from '@mui/material';

import feedback from '../../assets/images/feedback/color_face.png';
import pencil from '../../assets/images/feedback/pencil.png';

import { UserContext } from '../../core/user';

import { fetchSurveyResultScore } from '../../services/SurveyServices';
import { fetchUpdateUserSOF } from '../../services/UserServices';
import { fetchIncrementAccessCount, fetchSetStartTime, } from '../../services/SOFLogServices';

const Feedback = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const returnAddress = location.state;

    const { userState } = useContext(UserContext);
    const [state, setState] = useState(0);
    const uid = userState.uid;

    const angle = [70, 110, 180, -155];
    const pencilLocate = [35, 40, 45, 50];

    const loadSurveyResult = () => {
        fetchSurveyResultScore(uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    let sumOfScore = sumofResult(data.result);
                    thermometerFeedback(sumOfScore);
                });
            } else {
                console.log('score error');
            }
        });
    };

    useEffect(() => {
        loadSurveyResult();
    }, []);

    const sumofResult = (score) => {
        let sum = 0;
        for (let i = 0; i < score.length; i++) {
            sum += score[i];
        }
        return sum;
    };

    const thermometerFeedback = (result) => {
        // state 0 = 양호, 1 = 주의, 2 = 경고, 3 = 위험
        // console.log(result);
        if (result <= 10) setState(0);
        else if (result <= 15) setState(1);
        else if (result <= 25) setState(2);
        else setState(3);
    };

    const gotoNextpage = async () => {
        if (returnAddress !== null) navigate('/' + returnAddress.key);
        else {
            await fetchUpdateUserSOF(0);
            fetchSetStartTime('s');
            fetchIncrementAccessCount('s');
            navigate('/survey/S');
        }
    };

    const HighlightedText = styled('span')({
        color: '#191FBF',
        textDecoration: 'underline',
        fontWeight: '800',
    });

    const buttonStyle = {
        backgroundColor: '#526C6F',
        color: 'white',
        borderRadius: '15px',
        width: '120px',
        height: '48px',
        fontSize: '24px',
    };

    const stateString = (myState) => {
        switch (myState) {
            case 0:
                return '양호합니다.';
            case 1:
                return '다소 높습니다.';
            case 2:
                return '상당히 높습니다.';
            case 3:
                return '매우 높습니다.';
            default:
                return 'No statement';
        }
    };

    return (

        <div className="flex w-full flex-row items-center justify-center">
            <div className="flex flex-col w-982px px-5">
                <Typography
                    paddingTop="87px"
                    paddingBottom="15px"
                    fontSize="36px"
                    fontWeight="600"
                    textAlign="left"
                >
                    나의 스트레스 수준은?
                </Typography>

                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '982px',
                    }}
                />
                <div className="pb-5" />

                <div
                    className="background-inner"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: '66px',
                        backgroundColor: '#FFFFFF',
                    }}>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{ whiteSpace: 'pre-wrap', marginBottom: '27px', fontSize: '24px' }}
                        >
                            귀하의 스트레스는 <HighlightedText>{stateString(state)}</HighlightedText>
                        </Typography>

                        <div
                            style={{
                                width: '529px',
                                height: '250px',
                                position: 'relative',
                                zIndex: 1,
                                backgroundImage: `url(${feedback})`,
                                backgroundSize: `cover`,
                            }}
                        >
                            <img
                                src={pencil}
                                style={{
                                    width: '79.78px',
                                    height: '98.23px',
                                    position: 'absolute',
                                    top: '65%',
                                    transform: `rotate(${angle[state]}deg)`,
                                    left: `${pencilLocate[state]}%`,
                                    zIndex: 2,
                                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                                    // filter: 'drop-shadow(8px 4px 4px rgba(0, 0, 0, 0.25))',
                                }}
                            />
                        </div>

                        <Typography
                            variant="h6"
                            sx={{ widht: '687px', mt: 5, fontSize: '24px' }}
                        >
                            해당 결과는 마이페이지 ‘나의 상태’ 에서 다시 확인하실 수 있습니다.             
                        </Typography>
                    </div>
                    <div style={{ textAlign: 'right', marginTop: '50px', marginRight: '35px',}}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={buttonStyle}
                            onClick={gotoNextpage}
                        >
                            확인
                        </Button>
                    </div>

                <div className="pb-5" />
                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '982px',
                    }}
                />
            </div>

        </div>

    );
};

export default Feedback;
