import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography } from '@mui/material';

import agreement1 from '../../assets/images/agreement1.png';
import agreement2 from '../../assets/images/agreement2.png';

const Agreement = () => {
    const navigate = useNavigate();

    const styles = {
        container: {
            width: '70%',
            height: '300px',
            overflow: 'auto',
        },
        image: {
            width: '100%',
            height: 'auto',
        },
    };

    return (
        <div className="flex w-full">
            <div className="flex w-full items-center justify-center flex-col">
                <div className="flex w-full flex-col mb-6 justify-center">
                    <Typography fontWeight="bold" fontSize="24px">
                        개인정보 보호정책
                    </Typography>
                    <div className="p-6 flex w-full justify-center">
                        <div style={styles.container}>
                            <img
                                src={agreement1}
                                alt="agreement1"
                                style={styles.image}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full flex-col">
                    <Typography fontWeight="bold" fontSize="24px">
                        이용약관
                    </Typography>
                    <div className="p-6 flex w-full  justify-center">
                        <div style={styles.container}>
                            <img
                                src={agreement2}
                                alt="agreement1"
                                style={styles.image}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-end">
                    <Typography className="pr-3">
                        개인정보 보호정책 이용약관에 동의합니다.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: '#526C6F',
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: '700',
                            borderRadius: '20px',
                        }}
                        onClick={() => navigate('/signup')}
                    >
                        동의
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default Agreement;
