import React from 'react';
import Box3 from './Box3';
import QuizBox3 from './QuizBox3';
import YouTube from 'react-youtube';
import Typography from '@mui/material/Typography';

const parseVideoId = (url) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get('v');
}

const parsecontent = (content) => {
  return content.split('\n').map((line, index) => {
      const parts = line.split('*');
      return (
          <Typography key={index}>
              {parts.map((part, idx) => (
                  idx % 2 === 1 ? <u key={idx}>{part}</u> : part
              ))}
              <br />
          </Typography>
      );
  });
}

export default function Quiz5Module ({from_, content, subtitle, subcontent, quiz, answer, setAnswer, handleInputChange }) {
  const components = [];
  const parts = content.split('\n');

  parts.forEach((part, index) => {
    if (part.includes('!@#')) {
      components.push(<YouTube
          videoId={parseVideoId(from_)}
          opts={{
              height: '390',
              width: '640',
          }}
          style={{marginTop:25}}
      />)
    } else if (part.includes('$%^')) {
    //   components.push(<Box3 key={index} title={'실습'} quizlist={quiz.map((sentence, idx)=>(
    //     <div key={idx}>
    //       {parseQuiz(sentence)}
    //     </div>
    // ))} />);
      components.push(<Box3 key={index} title={subtitle.trim()} content={parsecontent(subcontent.trim())} />);
    } else {
      components.push(<Typography variant='h6' key={index} style={{marginTop:'10px'}} >{part}</Typography>);
    }
  });
  components.push(<QuizBox3 key={parts.length} title={'실습'} quizlist={quiz} setAnswer={setAnswer} answer={answer} handleInputChange={handleInputChange} />)
  return <div>{components}</div>;
};