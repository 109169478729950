import React from "react"
import { Typography } from "@mui/material"
import backgroundLetter from "../../../assets/images/image.png"

const replaceStarsWithInputs = (subcontent, handleInputChange, answer) => {
    let inputIndex = 0; // Initialize an input index counter

    return subcontent.split('\n').map((line, lineIndex) => {
        return (
            <Typography key={`line-${lineIndex}`} sx={{ mb: 2, fontSize: 16 }}>
                {line.split(/(\*+)/).map((segment, segmentIndex) => {
                    if (segment.startsWith('*')) {
                        const width = segment.length * 24; // Calculate width based on number of *
                        const currentInputIndex = inputIndex; // Capture the current input index
                        inputIndex++; // Increment the input index

                        return (
                            <input
                                key={`input-${lineIndex}-${segmentIndex}-${currentInputIndex}`} // Use a combination of lineIndex, segmentIndex, and inputIndex as the key
                                type="text"
                                style={{ 
                                    backgroundColor: '',
                                    border: '1px solid #000',
                                    width: `${width}px`,
                                    marginRight: '10px' }}
                                placeholder={width === 240 ? '  지지와 응원의 말을 적어주세요' : ''}
                                onChange={(event) => handleInputChange(currentInputIndex, event)} // Pass the current input index to handleInputChange
                                value={answer[currentInputIndex]}
                            />
                        );
                    } else {
                        return (
                            <React.Fragment key={`segment-${lineIndex}-${segmentIndex}`}>
                                {segment}
                            </React.Fragment>
                        );
                    }
                })}
                <br />
            </Typography>            
        );
    });
}


export default function Letter({ context, from, handleInputChange, answer }) {
    const transformedText = replaceStarsWithInputs(context, handleInputChange, answer);
    
    return (
        <div style={{ backgroundImage: `url(${backgroundLetter})`, backgroundSize: 'cover', marginTop: '50px', width: '100%' }}>
            <Typography>
                {transformedText}
            </Typography>
            <Typography sx={{ textAlign: 'right' }}>
                {from}로부터
            </Typography>
        </div>
    );
}
