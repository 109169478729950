import React, { useContext, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography, InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

import { fetchFindPassword } from '../../services/AuthServices';

const FindPassword = () => {
    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [phone, setPhone] = useState('');
    const [isError, setIsError] = useState(false);

    const onClickFindId = () => {
        const info = {
            id: id,
            phone: phone,
        };

        fetchFindPassword(info)
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        navigate('/update-password', {
                            state: { uid: data.uid },
                        });
                    });
                } else if (response.status === 404) {
                    // alert('Invalid phone number.');
                    setIsError(true);
                } else {
                    alert('Unknown error. Please try again later.');
                }
            })
            .catch((error) => {
                alert('Network error. Please try again later.');
                console.log(error);
            });
    };

    return (
        <div className="flex w-full">
            <div className="flex w-full items-center justify-center flex-col">
                <Typography
                    fontWeight="bold"
                    fontSize="24px"
                    className="pb-5"
                    fullWidth
                >
                    회원가입 시 입력한 아이디와 연락처를 입력하세요
                </Typography>
                <div className="pb-5">
                    <Typography fontWeight="bold">아이디</Typography>
                    <TextField
                        // label="연락처"
                        id="outlined-required"
                        onChange={(e) => setId(e.target.value)}
                        style={{ width: '545px' }}
                    />
                </div>
                <div className="pb-5">
                    <Typography fontWeight="bold">연락처</Typography>
                    <TextField
                        // label="연락처"
                        id="outlined-required"
                        style={{ width: '545px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneIcon color="black" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                {isError && (
                    <Typography color="error" className="pb-5">
                        아이디, 연락처가 일치하지 않습니다. 다시 입력해주세요.
                    </Typography>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: '#526C6F',
                        color: 'white',
                        fontSize: '24px',
                        fontWeight: '400',
                        borderRadius: '20px',
                    }}
                    onClick={onClickFindId}
                >
                    &nbsp;&nbsp;비밀번호 재설정&nbsp;&nbsp;
                </Button>
            </div>
        </div>
    );
};
export default FindPassword;
