import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function textBox(subtitle, subcontent) {
  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          sx={{ backgroundColor: '#D0D0D0', mt:3, border: '1px solid #000'}}
        >
          <Typography sx={{fontWeight:'bold'}}>{subtitle}</Typography>
        </AccordionSummary>
        <AccordionDetails
            sx={{ backgroundColor: '#FFF', border: '1px solid #000'}}
        >
          <Typography sx={{}}>
            {subcontent}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}