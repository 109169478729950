import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

// 그냥 네모 박스 테두리
const Box1 = ({ content, children }) => {
  return (
    <div style={{width:'100%'}}>
      <Accordion defaultExpanded>
        <AccordionDetails sx={{ backgroundColor: '#FFF', border: '1px solid #000' }}>
          <Typography>
            {content}
            {children}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Box1;
