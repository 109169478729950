import React from 'react';
import { useContext, useState, useEffect } from "react";
import { useNavigate, } from 'react-router-dom';

import { Button, Typography, Checkbox, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination  } from '@mui/material';

import { fetchBoard, fetchUpdateBoardNotice, fetchDeleteMultipleBoard, fetchDeleteMultipleComment, } from '../../services/BoardServices';
import { UserContext } from '../../core/user';

const Board= () => {
  const navigate = useNavigate();
  const { userState } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [boards, setBoard] = useState([]);
  const [checked, setChecked] = useState([]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const loadBoard = () => {
    fetchBoard().then((response) => {
        if (response.ok) {
            response.json().then((data) => {
                //console.log("board: ", data);
                setBoard(data);
                setChecked([]);
            });
        } else {
            setBoard([]);
        }
    });
  };

  useEffect(() => {
      loadBoard();
  }, []);

  const numCellStyle = {
    width: '63px',
    textAlign: 'center',
    border: '1px solid #B9B9B9',
    height: '67px',
  };

  const titleCellStyle = {
    textAlign: 'center',
    border: '1px solid #B9B9B9',
    height: '67px',
  };

  const dateCellStyle = {
    width: '179px',
    textAlign: 'center',
    border: '1px solid #B9B9B9',
    height: '67px',

  };

  const gotoWriteBoard = () => {
    if (!userState) alert('로그인 후 이용 가능합니다.');
    else navigate('/board/write-board');
  };

  const postsPerPage = 5;
  const totalPages = Math.ceil(boards.length / postsPerPage);

  const checkedBorad = (pid) => {    
    if (!checked.includes(pid)) checked.push(pid);
    else setChecked(checked.filter(item => item !== pid));
  };

  const handleAllCheck = (checked) => {
    if (checked.length === boards.length) {
      setChecked([]);
    } else {
      let pidList = [];
      boards.forEach((item) => pidList.push(item.pid));     
      setChecked(pidList);
    }
  }

  const handleChange = (event, value) => {
    setPage(value);
  };
  
  const handleCellClick = (cellNum) => {
    navigate(`/board/content-board/${cellNum}`);
  };

  const handletoMultipleDelete = () => {
    if (checked.length === 0) alert('선택된 게시글이 없습니다.');
 
    else {
      if (window.confirm('선택한 게시글을 삭제하시겠습니까?')) {

        fetchDeleteMultipleBoard(checked).then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              console.log(data);
              loadBoard();
            });
          } else {
            console.log('multiple board delete error!');
          }
        });
        fetchDeleteMultipleComment(checked).then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              console.log(data);
            });
          } else {
            console.log('multiple comment delete error!');
          }
        });
      }
    }

    
  };

  const registerNotice = () => {
    if (checked.length === 0) alert('선택된 게시글이 없습니다.');
    
    else {
      if (window.confirm('해당 게시글을 공지사항으로 등록하겠습니까?')) {
        const isNotice = true;
        fetchUpdateBoardNotice(checked, isNotice).then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              console.log(data);
              loadBoard();
            });
          } else {
            console.log('notice update error!');
          }
        });
      }
    } 
  };

  const CancleNotice = () => {
    if (checked.length === 0) alert('선택된 게시글이 없습니다.');
    
    else {
      if (window.confirm('해당 게시글을 공지사항에서 해제하겠습니까?')) {
        const isNotice = false;
        fetchUpdateBoardNotice(checked, isNotice).then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              console.log(data);
              loadBoard();
            });
          } else {
            console.log('notice update error!');
          }
        });
      }
    } 
  };

  const buttonStyle = {
    backgroundColor: '#526C6F',
    color: 'white',
    borderRadius: '15px',
    minWidth: '120px',
    height: '48px',
    fontSize: '24px',
    marginLeft: '10px',
    padding: '0 14px 0 14px',
  };

  const HighlightedText = styled('span')({
    fontWeight: '400',
    fontSize: '12px',
  });

  return (

    <div style={{ margin: '0 auto', width: '1269px', marginTop: '100px'}}>
      <Typography variant="h4"
        sx={{fontWeight: '700',  marginBottom: '15px',fontSize: '36px', }}>
        게시판
      </Typography>
      <Typography sx={{fontSize: '16px'}}>
        세이브에 참가중인 다른 분들과 소통할 수 있는 공간입니다. <HighlightedText> (문의사항이 있다면 이곳에 작성해 주세요) </HighlightedText>
      </Typography>

      <TableContainer component={Paper} sx={{ marginTop: '40px' }}> 
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ background:'#0000000F', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell sx={numCellStyle} style={{fontWeight: 'bold'}}>번호</TableCell>
              <TableCell sx={titleCellStyle} style={{fontWeight: 'bold'}}>제목</TableCell>
              <TableCell sx={dateCellStyle} style={{fontWeight: 'bold'}}>등록일</TableCell>
              {(userState && userState?.is_admin) && (<TableCell sx={dateCellStyle} style={{fontWeight: 'bold'}}>
                선택 
                <Checkbox {...label} onChange = {() => handleAllCheck(checked)} checked={ (boards.length === checked.length) && (boards.length != 0) ? true : false }/> 
                </TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {boards
                .filter(board => board.is_notice)
              // Sort rows by highest pid first
                .sort((a, b) => b.pid - a.pid)
                .map((board) => (
                <TableRow
                  key={board.pid}
                  sx={{ '&:last-child td, &:last-child th': { border: '1px solid #B9B9B9', }, backgroundColor: '#D9E2E3' }}
                >
                  <TableCell component="th" scope="row" sx={numCellStyle}>
                  공지
                  </TableCell>
                  <TableCell sx={titleCellStyle} onClick={() => handleCellClick(board.pid)}>{board.title}</TableCell>
                  <TableCell sx={dateCellStyle}>{board.date.split('T')[0]}</TableCell>
                  {(userState && userState?.is_admin) && (<TableCell sx={dateCellStyle}> <Checkbox {...label} onChange={() => checkedBorad(board.pid)} checked={ checked.includes(board.pid)? true : false }/> </TableCell>)}
                </TableRow>
              ))}
            {boards
              // Sort rows by highest pid first
                .sort((a, b) => b.pid - a.pid)
                .slice((page - 1) * postsPerPage, page * postsPerPage)
                .map((board) => (
                <TableRow
                  key={board.pid}
                  sx={{ '&:last-child td, &:last-child th': { border: '1px solid #B9B9B9' } }}
                >
                  <TableCell component="th" scope="row" sx={numCellStyle}>
                  {board.pid}
                  </TableCell>
                  <TableCell sx={titleCellStyle} onClick={() => handleCellClick(board.pid)}>{board.title}</TableCell>
                  <TableCell sx={dateCellStyle}>{board.date.split('T')[0]}</TableCell>
                  {(userState && userState?.is_admin) && (<TableCell sx={dateCellStyle}> <Checkbox {...label} onChange={() => checkedBorad(board.pid)} checked={ checked.includes(board.pid)? true : false }/> </TableCell>)}

                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
        {(userState && userState?.is_admin) && (
          <div>
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={registerNotice}
              >
              공지 등록
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={CancleNotice}
              >
              공지 해제
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handletoMultipleDelete}
              >
              삭제
            </Button>
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          style={buttonStyle}
          onClick={gotoWriteBoard}
          >
          글쓰기
        </Button>
      </div>

      <Pagination count={totalPages} page={page} variant="outlined" shape="rounded" 
        style={{display: 'flex', justifyContent: 'center', marginTop: '50px',}}
        onChange={handleChange} />
    </div>

  );
}

export default Board;