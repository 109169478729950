import React, { useState } from 'react';
import Box2 from './Box2';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

const parsecontent1 = (content) => {
  return content.split('\n').map((line, index) => {
    const parts = line.split('$');
    return (
      <Typography key={index} variant='h6' style={{marginTop:'10px'}}>
        {parts.map((part, idx) => (
          idx % 2 === 1 ? <span key={idx} className="blue-text">{part}</span> : part
        ))}
        <br />
      </Typography>
    );
  });
}

const parsecontent2 = (content, inputTexts, setInputTexts, setShowHints, showHints, exampleHint, index, answer, handleInputChange) => {
  return content.split('\n').map((line, idx) => {
    if (line.includes('**')) {
      return (
        <Typography key={idx} sx={{ textAlign: 'left' }}>
          {line.replace(/\*\*/g, '')}
          <input
            style={{ backgroundColor: '', border: '1px solid #000', width: '50%', marginRight: '10px' }}
            type="text"
            value={answer[index] || ''} // Bind input value for the specific question
            onChange={(e) => {
              const newInputTexts = [...inputTexts];
              newInputTexts[index] = e.target.value;
              setInputTexts(newInputTexts);
              handleInputChange(index, e)
            }} // Update inputTexts state for the specific question
          />
          <Button onClick={() => {
            const newShowHints = [...showHints];
            newShowHints[index] = true;
            setShowHints(newShowHints);
          }}>예시 보기</Button>

          {showHints[index] && (
            <Typography variant="body2" style={{ marginLeft:'157px', backgroundColor: '', border: '1px solid #000', width: '50%', marginRight: '10px' }}>
              {exampleHint}
            </Typography>
          )}
        </Typography>
      );
    } else {
      return (
        <Typography key={idx} sx={{ textAlign: 'left' }}>
          {line}
          <br />
        </Typography>
      );
    }
  });
}

export default function Quiz7Module({ content, subtitle, quiz, answer, handleInputChange, from_ }) {
  const [inputTexts, setInputTexts] = useState([]); // State to manage input values for all questions
  const [showHints, setShowHints] = useState([]); // State to control hint display for all questions
  const exampleHint = from_.split('\n');
  const components = [];
  const subtitleArray = subtitle.split('\n');

  components.push(parsecontent1(content.trim()));
  quiz.map((question, index) => {
    components.push(
      <Box2 key={index} title={subtitleArray[index]} content={parsecontent2(question, inputTexts, setInputTexts, setShowHints, showHints, exampleHint[index], index, answer, handleInputChange)} />
    );
    return null;
  });

  return <div style={{ width: '90%' }}>{components}</div>;
}
