import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button,
} from '@mui/material';

import { fetchSOFLogs, fetchHomeworkLogs } from '../../services/SOFLogServices';
import { fetchGetExcel } from '../../services/ExcelServies';

function createData(
    uid,
    id,
    s_start,
    s_end,
    s_accessCount,
    o_start,
    o_end,
    o_accessCount,
    f_start,
    f_end,
    f_accessCount,
) {
    return {
        uid,
        id,
        s_start,
        s_end,
        s_accessCount,
        o_start,
        o_end,
        o_accessCount,
        f_start,
        f_end,
        f_accessCount,
    };
}

function craeteHomeworkData(
    uid,
    s_firstStep_firstHomework,
    s_firstStep_secondHomework,
    s_secondStep_firstHomework,
    s_secondStep_secondHomework,
    o_firstStep_firstHomework,
    o_firstStep_secondHomework,
    o_secondStep_firstHomework,
    o_secondStep_secondHomework,
    f_firstStep_firstHomework,
    f_firstStep_secondHomework,
    f_secondStep_firstHomework,
    f_secondStep_secondHomework,
    progress,
) {
    return {
        uid,
        s_firstStep_firstHomework,
        s_firstStep_secondHomework,
        s_secondStep_firstHomework,
        s_secondStep_secondHomework,
        o_firstStep_firstHomework,
        o_firstStep_secondHomework,
        o_secondStep_firstHomework,
        o_secondStep_secondHomework,
        f_firstStep_firstHomework,
        f_firstStep_secondHomework,
        f_secondStep_firstHomework,
        f_secondStep_secondHomework,
        progress,
    };
}

const firstRow = [
    '모듈 시작 일시',
    '모듈 종료 일시',
    '모듈 소요 시간',
    '모듈 접속 횟수',
];

const secondRow = ['S', 'O', 'F'];

const homeworkRow = [
    'S1',
    'S2',
    'S3',
    'S4',
    'O1',
    'O2',
    'O3',
    'O4',
    'F1',
    'F2',
    'F3',
    'F4',
];

const AccessInfo = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [homework, setHomework] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState([]);
    const [visibleHomework, setVisibleHomework] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        fetchSOFLogs().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log(data);
                    createRows(data.result);
                });
            } else {
                alert('Failed to fetch users info');
            }
        });
        fetchHomeworkLogs().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log(data);
                    createHomeWorkRows(data.result);
                });
            } else {
                alert('Failed to fetch users info');
            }
        });
    }, []);

    const createRows = (usersInfo) => {
        const rows = [];
        // console.log('hi', usersInfo);
        usersInfo.forEach((user) => {
            rows.push(
                createData(
                    user.uid,
                    user.id,
                    user.s_start,
                    user.s_end,
                    user.s_accessCount,
                    user.o_start,
                    user.o_end,
                    user.o_accessCount,
                    user.f_start,
                    user.f_end,
                    user.f_accessCount,
                ),
            );
        });
        setUsers(rows);
    };

    const createHomeWorkRows = (info) => {
        const rows = [];

        info.forEach((user) => {
            rows.push(
                craeteHomeworkData(
                    user.uid,
                    user.s_firstStep_firstHomework,
                    user.s_firstStep_secondHomework,
                    user.s_secondStep_firstHomework,
                    user.s_secondStep_secondHomework,
                    user.o_firstStep_firstHomework,
                    user.o_firstStep_secondHomework,
                    user.o_secondStep_firstHomework,
                    user.o_secondStep_secondHomework,
                    user.f_firstStep_firstHomework,
                    user.f_firstStep_secondHomework,
                    user.f_secondStep_firstHomework,
                    user.f_secondStep_secondHomework,
                    user.progress,
                ),
            );
        });
        console.log('row', rows);

        setHomework(rows);
    };

    const calculateDuration = (start, end) => {
        if (start && end) {
            const startDate = new Date(start);
            const endDate = new Date(end);
            const diffInMs = endDate - startDate;
            const hours = Math.floor(diffInMs / (1000 * 60 * 60));
            const minutes = Math.floor(
                (diffInMs % (1000 * 60 * 60)) / (1000 * 60),
            );
            const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`; // Convert milliseconds to minutes
        }
        return null;
    };

    useEffect(() => {
        createVisibleRows();
    }, [page, rowsPerPage, users]);

    const createVisibleRows = () => {
        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        setVisibleUsers(users.slice(start, end));
        setVisibleHomework(homework.slice(start, end));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDownload = async () => {
        const response = await fetchGetExcel();
        if (response.ok) {
            console.log('Excel file downloaded successfully');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'access_info.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        } else {
            console.error('Failed to download Excel file');
        }
    };

    return (
        <div className="flex w-full flex-col pr-10">
            <div className="flex flex-row mb-7">
                <Typography fontSize="36px" fontWeight="700">
                    접속 기록
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleDownload}
                    style={{ marginLeft: '20px' }}
                >
                    다운로드
                </Button>
            </div>
            <div className="flex w-full flex-col overflow-x-auto">
                <TableContainer
                    component={Paper}
                    sx={{
                        maxWidth: '100%',
                        overflowX: 'auto',
                        '& table': {
                            minWidth: 650, // 테이블의 최소 너비 설정
                        },
                    }}
                >
                    <Table
                        sx={{ minWidth: 650 }}
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    style={{
                                        borderLeft: '1px  solid #000000',
                                        borderTop: '1px  solid #000000',
                                        borderBottom: '0px',
                                        borderRight: '1px  solid #000000',
                                    }}
                                >
                                    아이디
                                </TableCell>
                                {firstRow.map((col, idx) => (
                                    <TableCell
                                        align="center"
                                        colSpan={3}
                                        style={{ border: '1px solid #000000' }}
                                        key={idx}
                                    >
                                        {col}
                                    </TableCell>
                                ))}
                                <TableCell
                                    align="center"
                                    colSpan={12}
                                    style={{
                                        borderLeft: '1px  solid #000000',
                                        borderTop: '1px  solid #000000',
                                        borderBottom: '0px',
                                        borderRight: '1px  solid #000000',
                                    }}
                                >
                                    과제 소요 시간
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{
                                        borderLeft: '1px  solid #000000',
                                        borderTop: '1px  solid #000000',
                                        borderBottom: '0px',
                                        borderRight: '1px  solid #000000',
                                    }}
                                >
                                    실시간 진행률 (%)
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    style={{
                                        borderLeft: '1px  solid #000000',
                                        borderTop: '0px  solid #000000',
                                        borderRight: '1px  solid #000000',
                                        borderBottom: '1px  solid #000000',
                                    }}
                                />
                                {Array.from({ length: 4 }).map((_, idx) =>
                                    secondRow.map((col, idx) => (
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                            key={idx}
                                        >
                                            {col}
                                        </TableCell>
                                    )),
                                )}
                                {Array.from({ length: 1 }).map((_, idx) =>
                                    homeworkRow.map((col, idx) => (
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                            key={idx}
                                        >
                                            {col}
                                        </TableCell>
                                    )),
                                )}
                                <TableCell
                                    style={{
                                        borderLeft: '1px  solid #000000',
                                        borderTop: '0px  solid #000000',
                                        borderRight: '1px  solid #000000',
                                        borderBottom: '1px  solid #000000',
                                    }}
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleUsers.map((user) => {
                                const hw =
                                    homework.find((i) => i.uid === user.uid) ||
                                    {};
                                return (
                                    <TableRow
                                        key={user.id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.id}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.s_start}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.o_start}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.f_start}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.s_end}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.o_end}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.f_end}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {calculateDuration(
                                                user.s_start,
                                                user.s_end,
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {calculateDuration(
                                                user.o_start,
                                                user.o_end,
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {calculateDuration(
                                                user.f_start,
                                                user.f_end,
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.s_accessCount}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.o_accessCount}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {user.f_accessCount}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.s_firstStep_firstHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.s_firstStep_secondHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.s_secondStep_firstHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.s_secondStep_secondHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.o_firstStep_firstHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.o_firstStep_secondHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.o_secondStep_firstHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.o_secondStep_secondHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.f_firstStep_firstHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.f_firstStep_secondHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.f_secondStep_firstHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.f_secondStep_secondHomework}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                border: '1px solid #000000',
                                            }}
                                        >
                                            {hw.progress}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </div>
        </div>
    );
};
export default AccessInfo;
