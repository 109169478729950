import React, {
    createContext,
    useEffect,
    useState,
    useCallback,
    useContext,
} from 'react';

import PropTypes from 'prop-types';

import { fetchRefresh, fetchTokenTimeLeft } from '../services/AuthServices';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        isLoading: true,
    });

    const [tokenTimeLeft, setTokenTimeLeft] = useState(null);

    const refreshAuthToken = useCallback(() => {
        fetchRefresh()
            .then((response) => {
                if (response.ok) {
                    fetchTokenTimeLeft().then((timeResponse) => {
                        if (timeResponse.ok) {
                            timeResponse.json().then((data) => {
                                setTokenTimeLeft(data.time_left);
                                setAuthState({
                                    isAuthenticated: true,
                                    isLoading: false,
                                });
                            });
                        }
                    });
                } else {
                    setAuthState({
                        isAuthenticated: false,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                setAuthState({
                    isAuthenticated: false,
                    isLoading: false,
                });
            });
    }, []);

    const handleUserActivity = useCallback(() => {
        refreshAuthToken();
    }, [refreshAuthToken]);

    useEffect(() => {
        if (authState.isAuthenticated) {
            window.addEventListener('click', handleUserActivity);
            window.addEventListener('keydown', handleUserActivity);

            return () => {
                window.removeEventListener('click', handleUserActivity);
                window.removeEventListener('keydown', handleUserActivity);
            };
        }
    }, [authState.isAuthenticated, handleUserActivity]);

    useEffect(() => {
        // if (authState.isAuthenticated) {
        fetchRefresh()
            .then((response) => {
                if (response.status === 200) {
                    fetchTokenTimeLeft().then((timeResponse) => {
                        if (timeResponse.ok) {
                            timeResponse.json().then((data) => {
                                setTokenTimeLeft(data.time_left);
                                setAuthState({
                                    isAuthenticated: true,
                                    isLoading: false,
                                });
                            });
                        }
                    });
                } else {
                    setAuthState({
                        isAuthenticated: false,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setAuthState({
                    isAuthenticated: false,
                    isLoading: false,
                });
            });
        console.log('authState - AuthProvider', authState);
        // }
    }, []);

    return (
        <AuthContext.Provider
            value={{ authState, setAuthState, tokenTimeLeft, setTokenTimeLeft }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
