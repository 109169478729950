import React from 'react';
import Typography from '@mui/material/Typography';

import Letter2Background from '../../../assets/images/sofimage/letter2Background.png';

const ReadLetter2 = ({answer}) => {
  return (
    <div 
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${Letter2Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '40px',
        width: '80%',
        margin: '20px auto',
        fontFamily: 'cursive',
      }}
    >
        <div style={{ alignSelf: 'flex-start', marginBottom: '20px' }}>
        <Typography>
            사랑하는 {answer && answer[0] ? answer[0] : '     '} 에게
        </Typography>
        </div>

        <Typography variant="body1" style={{ marginBottom: '20px', width: '100%', minHeight:'200px'}}>
            {answer && answer[1] ? answer[1] : null}
        </Typography>

        <Typography variant="h6" style={{ textAlign: 'right', width: '100%' }}>
            너의 영원한 친구로부터
        </Typography>
    </div>
  );
};

export default ReadLetter2;
