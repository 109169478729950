import React, { useState, useEffect } from "react";
import { IconButton, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Typography } from "@mui/material";
import Box3 from './Box3';
import plusImage from '../../../assets/images/plusButton.png'

export default function QuizBox4({ title, quizlist, answer, setAnswer, handleInputChangeBasic }) {
    const [rows, setRows] = useState(['', '']);
    const firstrow = [quizlist[0], quizlist[1]]
    const handleAddRow = () => {
        setRows([...rows, '', '']);
        setAnswer([...answer, '', ''])
    };

    const handleDeleteRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index * 2, 2);
        setRows(newRows);

        // answer에서도 해당 인덱스를 삭제
        const newAnswer = [...answer];
        newAnswer.splice(index * 2 , 2);
        setAnswer(newAnswer);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...rows];
        const actualIndex = index * 2 + (field === 'activity' ? 1 : 0);
        newRows[actualIndex] = value;
        setRows(newRows);

        // 이벤트 객체를 생성하여 handleInputChangeBasic에 전달
        const event = { target: { value } };
        handleInputChangeBasic(actualIndex, event);
        console.log(answer);
    };


    const quizTable = () => (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">날짜</TableCell>
                    <TableCell align="center">활동</TableCell>
                    <TableCell align="center"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="center" sx={{ borderTop: '1.5px solid #000', borderRight: '1px solid' }}>
                        <Typography align="center">{firstrow[0]}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ borderTop: '1.5px solid #000' }}>
                        <Typography align="center">{firstrow[1]}</Typography>
                    </TableCell>
                </TableRow>
                {answer.reduce((acc, _, index) => {
                    if (index % 2 === 0) {
                        acc.push(
                            <TableRow key={index / 2}>
                                <TableCell align="center" sx={{ borderTop: '1.5px solid #000', borderRight: '1px solid' }}>
                                    <TextField
                                        value={answer[index]}
                                        onChange={(e) => handleInputChange(index / 2, 'date', e.target.value)}
                                        style={{ textAlign: 'center' }}
                                        inputProps={{ style: { textAlign: 'center' } }}
                                    />
                                </TableCell>
                                <TableCell align="center" sx={{ borderTop: '1.5px solid #000' }}>
                                    <TextField
                                        value={answer[index + 1]}
                                        onChange={(e) => handleInputChange(index / 2, 'activity', e.target.value)}
                                        style={{ textAlign: 'center' }}
                                        inputProps={{ style: { textAlign: 'center' } }}
                                    />
                                </TableCell>
                                <TableCell align="center" sx={{ borderTop: '1.5px solid #000' }}>
                                    {index !== 0 && (
                                        <Button variant="contained" color="secondary" onClick={() => handleDeleteRow(index / 2)} sx={{ borderRadius: '100px' }}>
                                            ㅡ
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    }
                    return acc;
                }, [])}
                <TableRow>
                    <TableCell colSpan={3} align="left">
                        <IconButton onClick={handleAddRow}>
                            <img src={plusImage} width={24} />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    return (
        <>
            <Box3 title={title} content={quizTable()} />
        </>
    );
}
