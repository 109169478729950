import React from 'react';
import Box3 from './Box3'
import Typography from '@mui/material/Typography';

// 파란색 텍스트 파싱 함수 + 기본 텍스트 파싱 함수
const parsingBasic = (content) => {
    return content.split('\n').map((line, index) => {
        // 먼저 $로 구분된 부분을 처리
        const dollarParts = line.split('$').map((part, idx) => (
            idx % 2 === 1 ? 
                <>
                    <span key={idx} style={{color: 'blue', marginBottom:'10px'}}>{part}</span>
                </> 
                : part
        ));

        // 이제 *로 구분된 부분을 처리
        const finalParts = dollarParts.map((segment, i) => {
            // segment가 string일 때만 *로 구분하여 밑줄 처리
            if (typeof segment === 'string') {
                const starParts = segment.split('*');
                return starParts.map((part, idx) =>
                    idx % 2 === 1 ? <u key={idx}>{part} <br /></u> : part
                );
            }
            return segment;
        });

        return (
            <Typography key={index}>
                {finalParts.flat()} {/* flat을 사용하여 중첩된 배열을 평평하게 만듦 */}
                <br />
            </Typography>
        );
    });
};

// quiz + input
const quizInput = (quizlist, answer, handleInputChange) => {
    if(quizlist[0] === '') return (
        <input 
            type="text" 
            style={{ border: '1px solid #000', width: '100%', height: '100px', marginTop:10}}
            value={answer[0] || ''}
            onChange={(e) => handleInputChange(0, e)}
        />
    );
    return quizlist.map((quiz, index) => {
        return (
            <Typography key={index} style={{ display: 'flex', alignItems: 'center', marginTop:'10px' }}>
                {quiz}
                <input 
                    type="text" 
                    style={{ flex: '1', border: '1px solid #000', marginLeft: '10px' }}
                    value={answer[index]}
                    onChange={(e) => handleInputChange(index, e)}
                />
            </Typography>
        );
    });
}

const parsecontent = (subtitle, subcontent, content, quiz, answer, handleInputChange, importImage, images) => {
    return content.split('\n').map((line, index) => {
        if(line.includes('!@#')) {
            return <Box3 key={index} title={subtitle} content={parsingBasic(subcontent)}/>;
        } else if(line.includes('$%^')) {
            return <Box3 key={index} title='실습' content={quizInput(quiz, answer, handleInputChange)}/>;
        }
        return (
            <Typography key={index} variant='h6' style={{marginTop:'10px'}}>
                {line}
                <br />
            </Typography>
        );
    });
}

// 파싱된 텍스트 컴포넌트
export default function Quiz9Module ({content, subtitle, subcontent, quiz, answer, handleInputChange}) {
    return (
        <div style={{width:'90%'}}>
            {parsecontent(subtitle, subcontent, content.trim(), quiz, answer, handleInputChange)}
        </div>
    )
};