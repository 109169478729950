import React from 'react';
import Box3 from './Box3';

import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// 문자열에서 ***와 &&&를 찾아 input 및 select 컴포넌트로 변환
const parsetext = (content, answer, handleInputChange, idx, images) => {

    let parts = content.split(/(\*\*\*|\*\*|&&&[^&]+&&&)/).map((part, index) => {
        if (part === '***') {
            // 형태는 typography로 감싸주기
            return (
                <React.Fragment key={`fragment-${index}`}>
                    <br />
                    <input key={`input-${index}`} type="text" style={{backgroundColor: '', border: '1px solid #000',  width: '95%', marginLeft: 15 }} value={answer[idx]} onChange={(e) => handleInputChange(idx, e)} />
                </React.Fragment>
            );
        } else if(part === '**') {
            return <input key={`input-${index}`} type="text" style={{backgroundColor: '', border: '1px solid #000', marginRight: 5}} value={answer[idx]} onChange={(e) => handleInputChange(idx, e)}/>;
        } else if(part.startsWith('&&&') && part.endsWith('&&&')) {
            const options = part.slice(3, -3).split(';');
            return (
                <Select
                    key={`select-${index}`}
                    value={answer[idx] || ''}
                    style={{ backgroundColor: '', border: '1px solid #000', margin: '5px', minWidth: 120, height:'30px'}}
                    onChange={(e) => handleInputChange(idx, e)}
                >
                    {options.map((option, i) => (
                        <MenuItem key={`option-${i}`} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            );
        } else {
            return <span key={`text-${index}`}>{part}</span>;
        }
    });
    return parts;
}

// 파싱된 텍스트 컴포넌트
export default function Quiz10Module ({ content, subtitle, subcontent, quiz, answer, handleInputChange }) {

    const quizlist = quiz.map((q, index) => {
        const parts = parsetext(q, answer, handleInputChange, index);
        return (
            <div key={index}>
                <Typography>{parts}</Typography>
            </div>
        )
    });

    return (
        <div style={{width:'90%'}}>
            {content}
            <Box3 title={subtitle} content={subcontent} />
            <Box3 title='실습' content={quizlist} />
        </div>
    )
};
