import React, { useState, useEffect } from "react";
import Box3 from "./Box3";
import { Typography, Button, Tooltip } from "@mui/material";
import plusButton from "../../../assets/images/plusButton.png";

const parseQuiz = (text, answer, handleInputChange, currentIndex) => {
    if(text.includes('**')) {
        return text.split('**').reduce((acc, part, index) => {
            if (index === 0) {
                return [part];
            }
            return [...acc, <input key={index} style={{ flex: '1', backgroundColor: '', border: '1px solid #000' }}  type="text" value={answer} onChange={(e)=>handleInputChange(currentIndex, e)} />, part];
        }, []);
    }    
};

export default function QuizBox3({ title, content, quizlist, answer, setAnswer, handleInputChange }) {
    const [inputs, setInputs] = useState([]);

    const addInput = () => {
        setInputs([...inputs, {}]);
        setAnswer([...answer, '']);
        console.log(answer)
    };

    useEffect(() => {
        setInputs(answer.slice(quizlist.length));
    }, [answer, quizlist.length]);

    const removeInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);

        const newAnswer = [...answer];
        newAnswer.splice(quizlist.length + index, 1);
        setAnswer(newAnswer);

        console.log(answer)
    };

    const combinedContent = [
        <Typography key="main-content">{content}</Typography>,
        ...quizlist.map((q, index) => {
            const currentIndex = index;
            return (
            <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Typography>{parseQuiz(q, answer[currentIndex], handleInputChange, currentIndex)}</Typography>
            </div>
        )}),
        ...inputs.map((_, index) => {
            const currentIndex = quizlist.length + index;
            return (
                <div key={currentIndex} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Typography>{parseQuiz(quizlist[quizlist.length - 1], answer[currentIndex], handleInputChange, currentIndex)}</Typography>
                    <Button onClick={() => removeInput(index)} style={{color:'#000', fontWeight:'bold'}}>ㅡ</Button>
                </div>
            );
        })
    ];

    return (
        <>
            <Box3 title={title} content={combinedContent}>
                <Tooltip title="칸 추가">
                    <Button onClick={addInput} style={{ display: 'flex', marginRight: 'auto', color:'#000', fontWeight:'bold' }} >
                        <img src={plusButton} alt="칸추가 버튼" width={20} style={{marginRight:'10px'}}/>칸 추가
                    </Button>
                </Tooltip>
            </Box3>
            
        </>
    );
}
