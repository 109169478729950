import React from 'react';
import QuizBox4 from './QuizBox4';
import Box3 from './Box3';

import Typography from '@mui/material/Typography';

function importAllImages(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '').replace(/\.[^/.]+$/, "")] = r(item); });
  return images;
}

const importImage = importAllImages(require.context('../../../assets/images/sofimage', false, /\.(png|jpe?g|svg)$/));


const parsecontent = (content, images) => {
  const lines = content.split('\n'); // 줄바꿈 기준으로 분리
  let imageIndex = 0; // 이미지 인덱스 초기화

  return lines.map((line, index) => {
      if (line.includes('##IMAGE##')) {
          // 이미지 태그를 생성
          const imgTMP = importImage[images[imageIndex]];
          imageIndex++; // 이미지 인덱스를 증가시켜 다음 이미지 사용
          return (
              <div key={index} style={{ textAlign: 'center', margin: '20px 0' }}>
                  <br />
                  <img src={imgTMP} alt={images[imageIndex - 1]} style={{ maxWidth: '50%', height: 'auto', display: 'block', margin: 'auto' }}  />
              </div>
          );
      }

      // 텍스트 부분 처리
      const parts = line.split('*');
      return (
          <Typography variant='h6' key={index} style={{ whiteSpace: 'pre-line', marginTop:'10px' }}>
              {parts.map((part, idx) =>
                  idx % 2 === 1 ? <u key={idx}>{part}</u> : part,
              )}
              <br />
          </Typography>
      );
  });
};


export default function Quiz6Module ({content, subtitle, subcontent, quiz, answer, setAnswer, handleInputChange }) {
  const components = [];

  components.push(content);
  if(subtitle != ''){
    components.push(
      <Box3 title={subtitle.trim()} content={parsecontent(subcontent.trim())} />
    )
  }

  components.push(
    <QuizBox4 title={'계획'} quizlist={quiz} answer={answer} setAnswer={setAnswer} handleInputChangeBasic={handleInputChange}/>
  )
  return <div>{components}</div>;
};