import React, { useState, useEffect } from 'react';
import Box3 from './Box3';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

export default function Quiz8Module({ content, subcontent, quiz, answer, setAnswer, handleInputChangeBasic }) {
    const [inputSets, setInputSets] = useState([]);

    useEffect(() => {
        initializeSets();
    }, []); // Initialize sets on component mount

    const initializeSets = () => {
        const sets = new Array(quiz.length).fill('');
        setInputSets(sets);
    };

    const createNewSet = () => {
        return ['', '', ''];
    };

    const handleAddSet = () => {
        const newSet = createNewSet();
        setInputSets([...inputSets, ...newSet]);
        setAnswer([...answer, ...newSet]);
    };

    const handleRemoveSet = (startIndex) => {
        const updatedSets = inputSets.filter(
            (input, index) => index < startIndex || index >= startIndex + 3
        );
        setInputSets(updatedSets);
        setAnswer(answer.filter(
            (input, index) => index < startIndex || index >= startIndex + 3
        ));
    };

    const handleInputChange = (index, value) => {
        const updatedSets = [...inputSets];
        updatedSets[index] = value;
        setInputSets(updatedSets);

        const updatedAnswer = [...answer];
        updatedAnswer[index] = value;
        setAnswer(updatedAnswer);
        console.log(answer);
    };

    const quizz = answer.map((input, index) => {
        if (index % 3 === 0) {
            return (
                <div key={index} style={{display:'flex', flexDirection:'row'}}>
                    <div style={{width:'50%'}}>
                    {[0, 1, 2].map((offset) => {
                        const idx = index + offset;
                        if (idx < answer.length) {
                            return (
                                <Typography key={idx} textAlign={'left'}>
                                    {quiz[idx%3]}
                                    <input
                                        style={{ backgroundColor: '', border: '1px solid #000', minWidth: '80%' }}
                                        type="text"
                                        value={answer[idx]}
                                        onChange={(e) => handleInputChange(idx, e.target.value)}
                                    />
                                </Typography>
                            );
                        }
                        return null;
                    })}
                    <br />
                    </div>
                    {index > 0 && (
                        <Button onClick={() => handleRemoveSet(index)}>삭제</Button>
                    )}
                </div>
            );
        }
        return null;
    });

    quizz.push(<Button key='add' onClick={handleAddSet}>추가</Button>);

    return (
        <div style={{ width: '90%' }}>
            {content}
            <Box3 title='실습' content={quizz} />
            <br />
            <br />
            {subcontent}
        </div>
    );
}
