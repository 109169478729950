import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Smodule from '../../assets/images/module/1. Smodule.png';
import Smhover from '../../assets/images/module/2. Smhover.png';
import Omnoact from '../../assets/images/module/3. Omnoact.png';
import Fmnoact from '../../assets/images/module/4. Fmnoact.png';
import Omodule  from '../../assets/images/module/5. Omodule.png';
import Omhover from '../../assets/images/module/6. Omhover.png';
import Fmodule from '../../assets/images/module/7. Fmodule.png';
import Fmhover from '../../assets/images/module/8. Fmhover.png';


import { UserContext } from '../../core/user';

import { Typography } from '@mui/material';
import { fetchAllCheckSOF } from '../../services/SOFServices';
import {
    fetchIncrementAccessCount,
    fetchSetStartTime,
} from '../../services/SOFLogServices';

const SOFStart = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState([true, false, false]);
    const [finishStep, setFinishStep] = useState([false, false, false]);
    const { userState } = useContext(UserContext);
    const [checklist, setChecklist] = useState({});

    const [SIsHovered, setSIsHovered] = useState(false);
    const [OIsHovered, setOIsHovered] = useState(false);
    const [FIsHovered, setFIsHovered] = useState(false);

    const handleSMouseEnter = () => {
        setSIsHovered(true);
    }

    const handleSMouseLeave = () => {
        setSIsHovered(false);
    }

    const handleOMouseEnter = () => {
        setOIsHovered(true);
    }

    const handleOMouseLeave = () => {
        setOIsHovered(false);
    }

    const handleFMouseEnter = () => {
        setFIsHovered(true);
    }

    const handleFMouseLeave = () => {
        setFIsHovered(false);
    }

    const handleClick = (what) => {
        if (what === 'S') {
            if (userState.sof === 'presurvey') navigate('/SOF/PreSurvey');
            else if (userState.sof === 'feedback') navigate('/SOF/Feedback');
            else {
                fetchIncrementAccessCount('s');
                fetchSetStartTime('s');
                navigate('/survey/S');
            }
        } else if (what === 'O') {
            fetchIncrementAccessCount('o');
            fetchSetStartTime('o');
            navigate('/survey/O');
        } else if (what === 'F') {
            if (userState.sof === 'postsurvey') navigate('/SOF/post-survey');
            else if (userState.sof === 'postfeedback')
                navigate('/SOF/post-feedback');
            else {
                fetchIncrementAccessCount('f');
                fetchSetStartTime('f');
                navigate('/survey/F');
            }
        }
    };

    useEffect(() => {
        console.log(userState);
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchAllCheckSOF(userState.uid);
                const checklist = await response.json();
                // setChecklist(checklist);
                // if (checklist.f1 && checklist.f2){
                //     setActiveStep([true, true, true]);
                //     setFinishStep([true, true, true]);
                // } else
                if (checklist.o1 && checklist.o2) {
                    setActiveStep([true, true, true]);
                    setFinishStep([true, true, false]);
                } else if (checklist.s1 && checklist.s2) {
                    setActiveStep([true, true, false]);
                    setFinishStep([true, false, false]);
                } else if (checklist.s1) {
                    setActiveStep([true, false, false]);
                    setFinishStep([false, false, false]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userState.uid]);

    const circleStyle = {
        width: '400px',
        height: '400px',
        cursor: 'pointer',
        filter: 'drop-shadow(8px 4px 4px rgba(0, 0, 0, 0.25))',
        margin: '0 100px',
        transition: 'transform 0.3s ease',
        borderRadius: '50%', // 원형으로 만들기 위해 추가
        objectFit: 'cover',  // 이미지가 원형에 맞게 채워지도록 추가 (필요한 경우)
    };
    

    const SCircle = (isActive, isFinish) => {
        if (isActive) {
            return (
                <img
                    src={SIsHovered ? Smhover : Smodule}
                    alt="Smodule"
                    style={circleStyle}
                    onClick={() => {
                        if (isFinish) {
                            alert('이미 완료한 단계입니다.');
                        } else if (isActive) {
                            handleClick('S');
                        }
                    }}
                    onMouseEnter={handleSMouseEnter}
                    onMouseLeave={handleSMouseLeave}
                />
            );
        } 
    };

    const OCircle = (isActive, isFinish) => {
        if (isActive) {
            return (
                <img
                    src={OIsHovered ? Omhover : Omodule}
                    alt="Omodule"
                    style={circleStyle}
                    onClick={() => {
                        if (isFinish) {
                            alert('이미 완료한 단계입니다.');
                        } else if (isActive) {
                            handleClick('O');
                        }
                    }}
                    onMouseEnter={handleOMouseEnter}
                    onMouseLeave={handleOMouseLeave}
                />
            );
        } else {
            return (
                <img
                    src={Omnoact}
                    alt="Omnoact"
                    style={circleStyle}
                />
            );
        }
    }

    const FCircle = (isActive, isFinish) => {
        if (isActive) {
            return (
                <img
                    src={FIsHovered ? Fmhover : Fmodule}
                    alt="Fmodule"
                    style={circleStyle}
                    onClick={() => {
                        if (isFinish) {
                            alert('이미 완료한 단계입니다.');
                        } else if (isActive) {
                            handleClick('F');
                        }
                    }}
                    onMouseEnter={handleFMouseEnter}
                    onMouseLeave={handleFMouseLeave}
                />
            );
        } else {
            return (
                <img
                    src={Fmnoact}
                    alt="Fmnoact"
                    style={circleStyle}
                />
            );
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
            }}
        >
            <div style={{ position: 'relative', textAlign: 'center' }}>
                {SCircle(activeStep[0], finishStep[0])}
                <Typography variant="h4" sx={{ mt: 2 }}>
                    나의 시간
                </Typography>
            </div>
            <div style={{ position: 'relative', textAlign: 'center' }}>
                {OCircle(activeStep[1], finishStep[1])}
                <Typography variant="h4" sx={{ mt: 2, color: activeStep[1] ? '#000' : 'gray' }}>
                    우리의 시간
                </Typography>
            </div>
            <div style={{ position: 'relative', textAlign: 'center' }}>
                {FCircle(activeStep[2], finishStep[2])}
                <Typography variant="h4" sx={{ mt: 2, color: activeStep[2] ? '#000' : 'gray' }}>
                    삶의 시간
                </Typography>
            </div>
        </div>
    );
};

export default SOFStart;
