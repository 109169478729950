import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, IconButton } from '@mui/material';
import YouTube from 'react-youtube';
import textBox from './Module/textBox';
import Letter from './Module/Letter';
import ReadLetter from './Module/ReadLetter';

import Text2Module from './Module/Text2';
import Text3Module from './Module/Text3';
import Quiz2Moudle from './Module/Quiz2';
import Quiz3Module from './Module/Quiz3';
import Quiz4Module from './Module/Quiz4';
import Quiz5Module from './Module/Quiz5';
import Quiz6Module from './Module/Quiz6';
import Quiz7Module from './Module/Quiz7';
import Quiz8Module from './Module/Quiz8';
import Quiz9Module from './Module/Quiz9';
import Quiz10Module from './Module/Quiz10';
import Quiz11Module from './Module/Quiz11';
import Quiz12Module from './Module/Quiz12';
import FeedbackModule from './Module/feedbackModule';
import Letter2 from './Module/Letter2';
import ReadLetter2 from './Module/ReadLetter2';

import rectangleimage from '../../assets/images/sofimage/image.png';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import ForwardIcon from '../../assets/images/ui/2. forward.png';
import ReplayIcon from '../../assets/images/ui/1. replay.png';

import { fetchUpdateUserSOF } from '../../services/UserServices';
import {
    fetchSetEndTime,
    loggingSOFAccess,
} from '../../services/SOFLogServices';


function importAllImages(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '').replace(/\.[^/.]+$/, "")] = r(item); });
    return images;
  }
  
const importImage = importAllImages(require.context('../../assets/images/sofimage', false, /\.(png|jpe?g|svg)$/));


const parsecontent = (content, images) => {
    const lines = content.split('\n'); // 줄바꿈 기준으로 분리
    let imageIndex = 0; // 이미지 인덱스 초기화

    return lines.map((line, index) => {
        if (line.includes('##IMAGE##')) {
            // 이미지 태그를 생성
            const imgTMP = importImage[images[imageIndex]];
            imageIndex++; // 이미지 인덱스를 증가시켜 다음 이미지 사용
            return (
                <div key={index} style={{ textAlign: 'center', margin: '20px 0' }}>
                    <br />
                    <img src={imgTMP} alt={images[imageIndex - 1]} style={{ maxHeight:'20vh', display: 'block', margin: 'auto' }}  />
                </div>
            );
        }

        // 텍스트 부분 처리
        const parts = line.split('*');
        return (
            <Typography variant='h6' key={index} style={{ whiteSpace: 'pre-line', marginTop:'10px' }}>
                {parts.map((part, idx) =>
                    idx % 2 === 1 ? <u key={idx}>{part}</u> : part,
                )}
                <br />
            </Typography>
        );
    });
};

const parseVideoId = (url) => {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
};

const SOF = ({
    step,
    setStep,
    doc,
    sof,
    setSOF,
    answer,
    setAnswer,
    finishSOF,
    setIsFirst,
    isFirst,
    fetchGetAllSecond,
    handleSubmitAnswer,
    type,
}) => {
    const navigate = useNavigate();
    const captureRef = useRef(null);

    useEffect(() => {
        let first = isFirst ? '1' : '2';
        loggingSOFAccess(first + '-' + type + '-' + step + '-in');
        fetchUpdateUserSOF(step);
    }, [step]);

    const handleInputChange = async (index, event) => {
        // Assuming you want to update the answer at a specific index
        await setAnswer((prevAnswers) => {
            const newAnswers = [...prevAnswers];
            newAnswers[index] = event.target.value;
            return newAnswers;
        });
        await handleSubmitAnswer();
    };

    const handleNext = async () => {
        // answer list 중에 하나라도 ''이면
        console.log(doc.quiz.length);
        // if (answer.includes('')) {
        //     console.log('안돼. 돌아가');
        // } else {
        await handleSubmitAnswer();
        let first = isFirst ? '1' : '2';
        await loggingSOFAccess(first + '-' + type + '-' + step + '-out');
        if (doc.type === 'feedback') {
            fetchSetEndTime(type);
        }
        await setStep(step + 1);
        console.log(answer);
        // }
    };

    const handlePrev = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const blueInfo = () => {
        return (
            <Typography sx={{ my: 5, color: '#191FBF', fontSize: '20px' }}>
                작성하신 내용은 마이페이지 &apos;나의 활동&apos;에서 확인실 수
                있습니다.
            </Typography>
        );
    };

    const YoutubeSAVE = () => {
        return (
            <YouTube
                videoId={parseVideoId(doc.content)}
                opts={{
                    height: '390',
                    width: '640',
                    playerVars: {
                        autoplay: 1,
                    },
                }}
            />
        );
    };

    const Text = () => {
        return (
            <>
                {parsecontent(doc.content, doc.images)}
            </>
        );
    };

    const Text2 = () => {
        return (
            <>
                <Text2Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                />
            </>
        );
    };

    const Text3 = () => {
        return (
            <>
                <Text3Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    quiz={doc.quiz}
                />
            </>
        );
    };

    const Quiz = () => {
        return (
            <>
                {parsecontent(doc.content)}
                {textBox(doc.subtitle, doc.subcontent)}
                {doc.quiz.map((q, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography>{q}</Typography>
                            <input
                                style={{
                                    flex: '1',
                                    // backgroundColor: '#D0D0D0',
                                    border: '1px solid #000',
                                }}
                                type="text"
                                onChange={(event) =>
                                    handleInputChange(index, event)
                                }
                                value={answer[index]}
                            />
                        </div>
                    );
                })}
            </>
        );
    };

    const Quiz2 = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div style={{ marginTop: '50px' }}>
                    <Quiz2Moudle
                        quiz={doc.quiz}
                        handleInputChange={handleInputChange}
                        answer={answer}
                    />
                </div>
            </>
        );
    };

    const Quiz3 = () => {
        return (
            <>
                <Quiz3Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz4 = () => {
        return (
            <>
                <Quiz4Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz5 = () => {
        return (
            <>
                <Quiz5Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    from_={doc.from_}
                    answer={answer}
                    handleInputChange={handleInputChange}
                    setAnswer={setAnswer}
                />
            </>
        );
    };

    const Quiz6 = () => {
        return (
            <>
                <Quiz6Module
                    content={parsecontent(doc.content, doc.images)}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz7 = () => {
        return (
            <>
                <Quiz7Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                    from_={doc.from_}
                />
            </>
        );
    };

    const Quiz8 = () => {
        return (
            <>
                <Quiz8Module
                    content={parsecontent(doc.content)}
                    subcontent={parsecontent(doc.subcontent)}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz9 = () => {
        return (
            <>
                <Quiz9Module
                    content={doc.content}
                    subcontent={doc.subcontent}
                    subtitle={doc.subtitle}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                />
                {doc.images && doc.images.length > 0 ? (
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    <br />
                    <img src={importImage[doc.images[0]]} alt={doc.images[0]} style={{ maxHeight:'20vh', display: 'block', margin: 'auto' }}  />
                </div>    
                ) : null}
            </>
        );
    };

    const Quiz10 = () => {
        return (
            <>
                <Quiz10Module
                    content={parsecontent(doc.content)}
                    subtitle={doc.subtitle}
                    subcontent={parsecontent(doc.subcontent)}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                    images={doc.images}
                />
                {doc.images && doc.images.length > 0 ? (
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    <br />
                    <img src={importImage[doc.images[0]]} alt={doc.images[0]} style={{ maxHeight:'20vh', display: 'block', margin: 'auto' }}  />
                </div>    
                ) : null}
                
            </>
        );
    };

    const Quiz11 = () => {
        return (
            <>
                <Quiz11Module
                    content={parsecontent(doc.content)}
                    subtitle={doc.subtitle}
                    subcontent={parsecontent(doc.subcontent)}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Quiz12 = () => {
        return (
            <>
                <Quiz12Module
                    content={doc.content}
                    subtitle={doc.subtitle}
                    subcontent={doc.subcontent}
                    quiz={doc.quiz}
                    answer={answer}
                    setAnswer={setAnswer}
                    handleInputChange={handleInputChange}
                />
            </>
        );
    };

    const Feedback = () => {
        return (
            <>
                <FeedbackModule
                    content={parsecontent(doc.content)}
                    quiz={doc.quiz}
                    answer={answer}
                    handleInputChange={handleInputChange}
                    setAnswer={setAnswer}
                />
            </>
        );
    };

    const LetterType = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div style={{ textAlign: 'left', width: '90%' }}>
                    <Letter
                        context={doc.subcontent}
                        from={doc.from_}
                        handleInputChange={handleInputChange}
                        answer={answer}
                    />
                </div>
            </>
        );
    };

    const Letter2Type = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div style={{ textAlign: 'left', width: '90%' }}>
                    <Letter2 handleInputChange={handleInputChange} answer={answer}/>
                </div>
            </>
        );
    };


    const ReadLetterType = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div ref={captureRef} style={{ textAlign: 'left', width: '90%' }}>
                    <ReadLetter
                        context={doc.subcontent}
                        from={doc.from_}
                        answer={answer}
                    />
                </div>
            </>
        );
    };

    const ReadLetter2Type = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <div style={{ textAlign: 'left', width: '90%' }}>
                    <ReadLetter2 
                        answer={answer}
                    />
                </div>
            </>
        );
    }


    const End = () => {
        console.log("is F1?", isFirst)
        return (
            <>
                {parsecontent(doc.content)}
                <div>
                    <Button
                        onClick={handleNext}
                        sx={{
                            backgroundColor: '#526C6F',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#003F5E',
                            },
                            '&:active': {
                                backgroundColor: '#004777',
                            },
                            mt: 5,
                            mr: 5,
                            paddingX: '24px',
                            fontSize: '24px',
                            borderRadius: '15px',
                        }}
                    >
                        다음에 하기
                    </Button>
                    <Button
                        onClick={() => {
                            fetchGetAllSecond().then((response) => {
                                if (response.ok) {
                                    response
                                        .json()
                                        .then((data) => {
                                            setSOF(data);
                                            setStep(0);
                                            return data;
                                        })
                                        .then((data) => {
                                            finishSOF();
                                            console.log(data);
                                            setIsFirst(false);
                                        });
                                }
                            });
                        }}
                        sx={{
                            backgroundColor: '#526C6F',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#003F5E',
                            },
                            '&:active': {
                                backgroundColor: '#004777',
                            },
                            mt: 5,
                            mr: 5,
                            paddingX: '24px',
                            fontSize: '24px',
                            borderRadius: '15px',
                        }}
                    >
                        2회기 시작하기
                    </Button>
                </div>
            </>
        );
    };

    const Finished = () => {
        return (
            <>
                {parsecontent(doc.content)}
                <Button
                    onClick={() => {
                        if (type === 'f' && !isFirst) {
                            fetchUpdateUserSOF('postsurvey');
                            navigate('/SOF/post-survey');
                            finishSOF();
                        } else {
                            finishSOF();
                            fetchUpdateUserSOF(0);
                            navigate('/SOF');
                        }
                    }}
                    sx={{
                        backgroundColor: '#526C6F',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#003F5E',
                        },
                        '&:active': {
                            backgroundColor: '#004777',
                        },
                        mt: 5,
                        paddingX: '24px',
                        fontSize: '24px',
                        borderRadius: '15px',
                    }}
                >
                    확인
                </Button>
            </>
        );
    };

    return (
        <div className="flex justify-center">
            <div
                className="mt-10 flex flex-col"
                style={{ width: '80%' }}
            >
                <Typography
                    variant="h3"
                    sx={{ mb:2}}
                >
                    {doc.title}
                </Typography>
                    <div
                        className="background-outer flex flex-row items-center"
                        style={{
                            width: '100%',
                            minHeight: '540px',
                            height: 'auto',
                            backgroundColor: '',
                            position: 'relative',
                            borderTop: '1.5px solid #000',
                            borderBottom: '1.5px solid #000',
                            padding: '20px',
                            marginBottom: '30px',
                        }}
                    >
                        {doc.category && (
                            <Typography
                                variant="h5"
                                sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    padding: '5px',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                }}
                            >
                                <img src={rectangleimage} alt="" style={{height:'36px', marginRight:'10px'}} />
                                {doc.category}
                            </Typography>
                        )}
                        <IconButton
                            sx={{
                                position: 'relative',
                                left: '0',
                                transform: 'rotate(180deg)',
                            }}
                            disabled={step === 0 || doc.type === 'finish'}
                            onClick={handlePrev}
                        >
                            {step === 0 || doc.type === 'finish' ?
                                null : <img src={ForwardIcon} alt="" style={{width:'50px'}} />
                            }
                        </IconButton>
                        
                        {doc.type === 'end' || doc.type === 'finish' || doc.type === 'url' ? null : (
                            
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                            }}
                            // onClick={} // 클릭 핸들러 추가
                        >
                            <div>
                                <img src={ReplayIcon} alt="Replay" style={{width:'30px', marginLeft:'10px'}} />
                                <Typography variant='caption'>
                                    다시 듣기
                                </Typography>
                            </div>

                        </IconButton>
                        )}
                        <div
                            className="flex flex-col items-center justify-center"
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#FFFFFF',
                                padding: '20px',
                                textAlign: 'center',
                            }}
                        >
                            {doc.type == 'text'
                                ? Text()
                                : doc.type == 'text2'
                                  ? Text2()
                                  : doc.type == 'text3'
                                    ? Text3()
                                    : doc.type == 'url'
                                      ? YoutubeSAVE()
                                      : doc.type == 'quiz'
                                        ? Quiz()
                                        : doc.type == 'quiz2'
                                          ? Quiz2()
                                          : doc.type == 'quiz3'
                                            ? Quiz3()
                                            : doc.type == 'quiz4'
                                              ? Quiz4()
                                              : doc.type == 'quiz5'
                                                ? Quiz5()
                                                : doc.type == 'quiz6'
                                                  ? Quiz6()
                                                  : doc.type == 'quiz7'
                                                    ? Quiz7()
                                                    : doc.type == 'quiz8'
                                                      ? Quiz8()
                                                      : doc.type == 'quiz9'
                                                        ? Quiz9()
                                                        : doc.type == 'quiz10'
                                                          ? Quiz10()
                                                          : doc.type == 'quiz11'
                                                            ? Quiz11()
                                                            : doc.type ==
                                                                'quiz12'
                                                              ? Quiz12()
                                                              : doc.type ==
                                                                  'end'
                                                                ? End()
                                                                : doc.type ==
                                                                    'letter'
                                                                  ? LetterType()
                                                                  : doc.type ==
                                                                      'readLetter'
                                                                    ? ReadLetterType()
                                                                    : doc.type ==
                                                                        'feedback'
                                                                      ? Feedback()
                                                                      : doc.type ==
                                                                          'finish'
                                                                        ? Finished()
                                                                        : doc.type ==
                                                                            'letter2'
                                                                            ? Letter2Type()
                                                                            : doc.type ==
                                                                                'readletter2'
                                                                                ? ReadLetter2Type()
                                                                                : null}

                            {/* {doc.blue ? blueInfo() : null} */}
                        </div>
                        <IconButton
                            sx={{
                                position: 'relative',
                                left: '0',
                            }}
                            disabled={
                                doc.type === 'end' ||
                                doc.type === 'finish' ||
                                doc.type === 'feedback'
                            }
                            onClick={handleNext}
                        >
                            {doc.type === 'end' ||
                                doc.type === 'finish' ||
                                doc.type === 'feedback' ?
                                null : <img src={ForwardIcon} alt="" style={{width:'50px'}} />
                            }
                        </IconButton>
                    </div>

                    {doc.type === 'feedback' && (
                        <Button
                            onClick={handleNext}
                            sx={{
                                backgroundColor: '#526C6F',
                                color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#003F5E',
                                },
                                '&:active': {
                                    backgroundColor: '#004777',
                                },
                                ml: 'auto',
                                mt: 1,
                                mr: 10,
                                mb: -2,
                                paddingX: '24px',
                                fontSize: '24px',
                                borderRadius: '15px',
                            }}
                        >
                            제출
                        </Button>
                    )}
                
            </div>
        </div>
    );
};

export default SOF;
