import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Typography, styled, Tooltip, Box } from '@mui/material';

import { fetchGetMenu, fetchGetAllO1, fetchGetAllO2, fetchGetAllF1, fetchGetAllF2, fetchGetAllS1, fetchGetAllS2 } from '../../services/SOFServices';

const VerticalNavbar = ({ isExpanded, toggleNavbar, setStep, type, isFirst, setIsFirst, setSOF, userState, canGoToMenu }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { pid } = useParams();
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        fetchGetMenu(type).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    setMenu(data);
                });
            }
        });
        
    }, []);


    const handleListItemClick = (step, isFirstMenu) => {
        if((isFirst && isFirstMenu) || !(isFirst || isFirstMenu)){
            setStep(step-1)
        } else if (!isFirst && isFirstMenu){
            setIsFirst(true)
            if (type == 's'){
                fetchGetAllS1().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step-1)
                        });
                    }
                });
            }
            else if (type == 'o'){
                fetchGetAllO1().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step-1)
                        });
                    }
                });
            }
            else if (type == 'f'){
                fetchGetAllF1().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step-1)
                        });
                    }
                });
            }
            
        } else if (isFirst && !isFirstMenu){
            setIsFirst(false)
            if (type == 'o'){
                fetchGetAllO2().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step-1)
                        });
                    }
                });
            } else if (type == 's'){
                fetchGetAllS2().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step-1)
                        });
                    }
                });
            } else if (type == 'f'){
                fetchGetAllF2().then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setSOF(data);
                            setStep(step-1)
                        });
                    }
                });
            }
        }
    };


    return (
        <>
            <div>
                <Tooltip title="Toggle Navbar" arrow>
                    <Button onClick={toggleNavbar}>
                        <MenuIcon fontSize="large" color='black' />
                    </Button>
                </Tooltip>
            </div>

            <div className="flex flex-col justify-center transition-width duration-300" style={{backgroundColor:'#526C6F', color:'white'}}>
                {isExpanded ? (
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        sx={{ height: '83vh', minWidth: '200px' }}
                    >
                        {menu.map((item) => (
                            <ListItemButton
                                key={item.id}
                                selected={pathname === `/Project/${pid}/${item.label}`}
                                disabled={(!item.first && canGoToMenu[0] == 1) || (item.first && canGoToMenu[0] == 1 && item.goto > canGoToMenu[1] || (!item.first && canGoToMenu[0] == 2 && item.goto > canGoToMenu[1]))}
                                onClick={() => handleListItemClick(item.goto, item.first)}
                                sx={{ mb: 2 }}
                            >
                                <Box>
                                    <Typography variant='h6'>
                                        {item.label}
                                    </Typography>
                                    {item.sub && (
                                        <Typography variant='body2'>
                                            {item.sub}
                                        </Typography>
                                    )}
                                </Box>
                            </ListItemButton>
                        ))}
                    </List>
                ) : null}
            </div>
        </>
    );
};

export default VerticalNavbar;