import React, { useState, useEffect } from "react";
import Box3 from "./Box3";
import { Typography, Button, Tooltip } from "@mui/material";
import plusButton from "../../../assets/images/plusButton.png";

const parseQuiz = (text, answer, handleInputChange, baseIndex) => {
    const parts = text.split('**');
    return parts.reduce((acc, part, index) => {
        if (index === 0) {
            return [part];
        }
        return [
            ...acc,
            <input
                key={baseIndex + index - 1}
                style={{ flex: '1', backgroundColor: '', border: '1px solid #000' }}
                type="text"
                value={answer[baseIndex + index - 1] || ''}
                onChange={(e) => handleInputChange(baseIndex + index - 1, e)}
            />,
            part
        ];
    }, []);
};

export default function QuizBox5({ title, content, quizlist, answer, setAnswer, handleInputChange }) {
    const [additionalQuizzes, setAdditionalQuizzes] = useState([]);
    
    const addInput = () => {
        const newInputs = [...additionalQuizzes, quizlist.length * 2 + additionalQuizzes.length];
        setAdditionalQuizzes(newInputs);
        setAnswer([...answer, '', '']);
    };

    useEffect(() => {
        const additionalInputs = (answer.length - quizlist.length * 2) / 2;
        setAdditionalQuizzes(Array.from({ length: additionalInputs }, (_, i) => quizlist.length * 2 + i * 2));
    }, [answer, quizlist.length]);

    const removeInput = (index) => {
        const newAdditionalQuizzes = [...additionalQuizzes];
        newAdditionalQuizzes.splice(index, 1);
        setAdditionalQuizzes(newAdditionalQuizzes);

        const baseIndex = quizlist.length * 2 + index * 2;
        const newAnswer = [...answer];
        newAnswer.splice(baseIndex, 2);
        setAnswer(newAnswer);
    };

    const combinedContent = [
        <Typography key="main-content">{content}</Typography>,
        ...quizlist.map((q, index) => (
            <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Typography>{parseQuiz(q, answer, handleInputChange, index * 2)}</Typography>
            </div>
        )),
        ...additionalQuizzes.map((inputIndex, index) => (
            <div key={inputIndex} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Typography>{parseQuiz(quizlist[0], answer, handleInputChange, inputIndex)}</Typography>
                <Button onClick={() => removeInput(index)} style={{ color: '#000', fontWeight: 'bold' }}>ㅡ</Button>
            </div>
        ))
    ];

    return (
        <>
            <Box3 title={title} content={combinedContent}>
                <Tooltip title="칸 추가">
                    <Button onClick={addInput} style={{ display: 'flex', marginRight: 'auto', color:'#000', fontWeight:'bold' }} >
                        <img src={plusButton} alt="칸추가 버튼" width={20} style={{ marginRight: '10px' }} />칸 추가
                    </Button>
                </Tooltip>
            </Box3>
        </>
    );
}
