import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography, Divider } from '@mui/material';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="flex w-full flex-col">
            <div className="flex w-full items-center justify-center ">
                <div
                    className="background-outer p-10"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginLeft: '235px',
                        marginRight: '235px',
                        width: '1200px',
                        height: '540px',
                        backgroundColor: '#BAD6DB',
                        borderRadius: '44px',
                    }}
                >
                    <div
                        className="mb-5"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '1123px',
                            height: '416px',
                            backgroundColor: '#FFFFFF',
                        }}
                    >
                        <Typography textAlign="center">
                            SAVE 전반적인 소개
                            <br /> 홈/게시판/도움말/마이페이지 이용 안내
                            <br />
                            <br />
                            ‘도움말’에서 다시 확인하실 수 있습니다.
                        </Typography>
                    </div>
                    <div className="flex w-full place-content-end">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                backgroundColor: '#004777',
                                color: 'white',
                            }}
                            onClick={() => navigate('/SOF')}
                        >
                            확인
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Home;
