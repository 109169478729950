import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import agreement2 from '../../assets/images/agreement2.png';

const TermsOfPolicy = () => {
    const navigate = useNavigate();

    const styles = {
        container: {
            width: '70%',
            height: '300px',
            overflow: 'auto',
        },
        image: {
            width: '100%',
            height: 'auto',
        },
    };

    return (
        <div className="flex w-full">
            <div className="flex w-full items-center justify-center flex-col">
                <div className="flex w-full flex-col">
                    <Typography variant="h4" fontWeight="bold">
                        이용약관
                    </Typography>
                    <div className="p-6 flex w-full  justify-center">
                        <div style={styles.container}>
                            <img
                                src={agreement2}
                                alt="agreement1"
                                style={styles.image}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TermsOfPolicy;
