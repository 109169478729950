import React, {useState} from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Radio, FormControlLabel } from '@mui/material';

export default function feedbackModule ({content, quiz, answer, setAnswer, handleInputChange}) {
    
    const handleSurveyChange = (index, value, e) => {
        const newResults = [...answer];
        console.log(index)
        if (index > 0)
            if (answer[index - 1] == '') {
                alert((index) + "번 문항이 체크되지 않았습니다.");
            }
            else {
                newResults[index] = value;
                handleInputChange(index, e);
            }
        if (index == 0) {
            newResults[index] = value;
            handleInputChange(index, e);
        }
        console.log(answer);
    };

    return (
        <>
            <br />
            {content}
            <TableContainer component={Paper} sx={{mt:5}}>
                <Table>
                    <TableHead>
                        <TableRow sx={{background: 'rgba(0, 0, 0, 0.06)',  }}>
                            <TableCell sx={{ border: '1px #B9B9B9 solid', whiteSpace: 'pre-wrap'}}>항목</TableCell>
                            <TableCell sx={{ width: '70px', border: '1px #B9B9B9 solid', whiteSpace: 'pre-wrap',}}>1</TableCell>
                            <TableCell sx={{ width: '70px', border: '1px #B9B9B9 solid', whiteSpace: 'pre-wrap',}}>2</TableCell>
                            <TableCell sx={{ width: '70px', border: '1px #B9B9B9 solid', whiteSpace: 'pre-wrap',}}>3</TableCell>
                            <TableCell sx={{ width: '70px', border: '1px #B9B9B9 solid', whiteSpace: 'pre-wrap',}}>4</TableCell>
                            <TableCell sx={{ width: '70px', border: '1px #B9B9B9 solid', whiteSpace: 'pre-wrap', }}>5</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quiz.map((q, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{border: '1px #B9B9B9 solid'}}>{q}</TableCell>
                                <TableCell sx={{border: '1px #B9B9B9 solid'}}>
                                    <FormControlLabel
                                    control={<Radio />}
                                    value="0"
                                    checked={answer[index] === '0' || answer[index] === 0}
                                    onChange={(e) => handleSurveyChange(index, 0, e)}
                                    />
                                </TableCell>
                                <TableCell sx={{ border: '1px #B9B9B9 solid'}}>
                                    <FormControlLabel
                                    control={<Radio />}
                                    value="1"
                                    checked={answer[index] === '1' || answer[index] === 1}
                                    onChange={(e) => handleSurveyChange(index, 1, e)}
                                    />
                                </TableCell>
                                <TableCell sx={{border: '1px #B9B9B9 solid'}}>
                                    <FormControlLabel
                                    control={<Radio />}
                                    value="2"
                                    checked={answer[index] === '2' || answer[index] === 2}
                                    onChange={(e) => handleSurveyChange(index, 2, e)}
                                    />
                                </TableCell>
                                <TableCell sx={{border: '1px #B9B9B9 solid'}}>
                                    <FormControlLabel
                                    control={<Radio />}
                                    value="3"
                                    checked={answer[index] === '3' || answer[index] === 3}
                                    onChange={(e) => handleSurveyChange(index, 3, e)}
                                    />
                                </TableCell>
                                <TableCell sx={{border: '1px #B9B9B9 solid'}}>
                                    <FormControlLabel
                                    control={<Radio />}
                                    value="4"
                                    checked={answer[index] === '4' || answer[index] === 4}
                                    onChange={(e) => handleSurveyChange(index, 4, e)}
                                    />
                                </TableCell>
                            </TableRow>
                            ))}
                            <TableRow sx={{border: '1px #B9B9B9 solid',}}>
                                <TableCell colSpan={6}>
                                    <Typography>
                                        소감 또는 의견
                                    </Typography>
                                    <input style={{width:'100%', height:'100px', backgroundColor: '', border: '1px solid #000' }} type="text" value={answer[2]} onChange={(e)=>handleInputChange(2, e)}/>
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}