import React from "react";
import { Typography } from "@mui/material";
import backgroundLetter from "../../../assets/images/image.png";

const replaceStarsWithInputs = (context,answer) => {
  let valueIndex = 0;
  return context.split('\n').map((line, lineIndex) => (
    <Typography key={lineIndex} sx={{ mb: 2, fontSize: 16 }}>
      {line.split('*').map((segment, segmentIndex) => (
        <React.Fragment key={segmentIndex}>
          {segment}
          {segmentIndex !== line.split('*').length - 1 && 
            answer[valueIndex++]
          }
        </React.Fragment>
      ))}
      <br />
    </Typography>
  ));
};

export default function ReadLetter({ context, from, answer }) {
  const transformedText = replaceStarsWithInputs(context, answer);
  return (
    <div style={{ backgroundImage: `url(${backgroundLetter})`, backgroundSize: 'cover', marginTop: '50px', width: '100%', padding:'10px' }}>
      <Typography>
        {transformedText}
      </Typography>
      <Typography sx={{ textAlign: 'right' }}>
        {from}로부터
      </Typography>
    </div>
  );
}
