import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField, Typography, Divider } from '@mui/material';

import forward from '../../assets/images/ui/2. forward.png';
import smodule from '../../assets/images/module/1. Smodule.png';
import omodule from '../../assets/images/module/5. Omodule.png';
import fmodule from '../../assets/images/module/7. Fmodule.png';

import { fetchUpdateIsFirstLogin } from '../../services/UserServices';

const Help = () => {
    const navigate = useNavigate();
    const [currentComponent, setCurrentComponent] = useState(0);
    const onClickConfirm = () => {
        fetchUpdateIsFirstLogin();
        navigate('/');
    };

    const component = [
        <div key="module1" className="flex flex-col w-full items-center">
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingBottom="30px"
            >
                세이브(SAVE)는 암환자분들이 경험하는 여러 어려움을 다루는 데
                도움을 드리고자 만들어졌습니다.
                <br />
                세이브는 아래와 같이 총 3개 모듈(1모듈 당 40분)이{' '}
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '5px',
                    }}
                >
                    순서대로 진행
                </span>
                되며,
                <br />
                하나의 모듈은 2부(1부 당 20분)로 구성되어 있습니다.
            </Typography>
            <div>
                <div className="pb-3">
                    <div className="flex flex-row">
                        <img
                            src={smodule}
                            style={{ width: '70px', height: '70px' }}
                        />
                        <Typography
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="30px"
                            textAlign="left"
                            paddingLeft={3}
                        >
                            1. &apos;나의 시간&apos; 모듈에서는 나와 관련된
                            어려움을 다룹니다.
                            <br /> &nbsp;&nbsp;&nbsp;&nbsp;나의 변환된 모습을
                            수용하고, 균형있는 시선으로 바라보는 방법을
                            배웁니다.
                        </Typography>
                    </div>
                </div>
                <div className="pb-3">
                    <div className="flex flex-row">
                        <img
                            src={omodule}
                            style={{ width: '70px', height: '70px' }}
                        />
                        <Typography
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="30px"
                            textAlign="left"
                            paddingLeft={3}
                        >
                            2. &apos;우리의 시간&apos;모듈에서는 대인관계
                            어려움을 다룹니다.
                            <br /> &nbsp;&nbsp;&nbsp;&nbsp;나와 상대방의 마음을
                            이해하고, 공감적으로 소통하는 방법을 배웁니다.
                        </Typography>
                    </div>
                </div>
                <div>
                    <div className="flex flex-row">
                        <img
                            src={fmodule}
                            style={{ width: '70px', height: '70px' }}
                        />
                        <Typography
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="30px"
                            textAlign="left"
                            paddingLeft={3}
                        >
                            3. &apos;미래의 시간&apos;모듈에서는 미래와 관련된
                            어려움을 다룹니다.
                            <br /> &nbsp;&nbsp;&nbsp;&nbsp;현재에 온전히 머물고,
                            삶에서 소중한 것을 점검하는 방법을 배웁니다.
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingTop="30px"
            >
                각 부에서는 주제와 관련된 사례와 함께 어려움을 다룰 수 있는 여러
                방법들이 제시됩니다.
                <br />
                작성칸에 자신의 생각을 자유롭게 적어보시면서, 제시된 방법들을
                연습해보실 수 있습니다.
            </Typography>
        </div>,
        <div key="module2">
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingTop="30px"
            >
                한 모듈을 완료하시면, 다음날부터 다음 모듈을 하실 수 있습니다.
                <br />
                완료한 모듈은 언제든지 다시 하실 수 있습니다.
                <br />
                상단의 [마이페이지]에서 작성하신 내용을 다운로드 받으실 수
                있습니다.
            </Typography>
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingTop="30px"
            >
                앞으로 3주 간,{' '}
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '5px',
                    }}
                >
                    매주 하나의 모듈을 완료
                </span>
                하시면 됩니다.
                <br /> 한주가 지났음에도 한 모듈이 완료되지 않았을 경우, 가입 시
                입력하신 연락처로 상기문자가 전송됩니다.
                <br />{' '}
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '5px',
                    }}
                >
                    하루 최대 2부까지 진행
                </span>
                하실 수 있으니, 자신의 페이스에 맞춰 여유있게 진행해보세요.
            </Typography>
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingTop="30px"
            >
                진행 중{' '}
                <span
                    style={{
                        fontWeight: '700',
                        textDecoration: 'underline',
                        textUnderlineOffset: '5px',
                    }}
                >
                    20분 이상 아무런 활동이 없다면 자동 로그아웃
                </span>
                이 됩니다.
                <br />
                상단의 [로그인 연장] 버튼을 누르시면 시간을 연장할 수 있습니다.
                <br />
                자동 로그아웃이 되어도 이전까지 작성하셨던 내용은 모두
                저장됩니다.
            </Typography>
        </div>,
        <div key="module3" className="flex flex-col w-full items-center">
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingBottom="30px"
            >
                마지막으로 가능한 집중할 수 있는 공간에서 진행해보세요.
                <br />
                여유를 가지고 배운 방법을 익히는 데 도움이 될 것입니다.
            </Typography>
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingBottom="30px"
            >
                이외 문의사항은 상단의 [게시판]에 남겨주세요.
            </Typography>
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingBottom="30px"
            >
                이제 준비가 되셨다면, 세이브를 시작하겠습니다. <br />
                앞으로의 여정이 당신에게 따뜻한 위로와 도움이 되길 바랍니다.
            </Typography>
            <Typography
                fontSize="18px"
                fontWeight="400"
                lineHeight="36px"
                textAlign="center"
                paddingBottom="30px"
            >
                내용을 모두 확인하셨다면 아래 [확인] 버튼을 눌러주세요.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                style={{
                    backgroundColor: '#526C6F',
                    color: 'white',
                    fontSize: '24px',
                    fontWeight: '400',
                    width: '147px',
                    borderRadius: '20px',
                }}
                onClick={onClickConfirm}
            >
                확인
            </Button>
        </div>,
    ];

    const handlePrev = () => {
        // 마지막 요소일 때는 더 이상 증가하지 않음
        if (currentComponent > 0) {
            setCurrentComponent((prevIndex) => prevIndex - 1);
        }
    };

    const handleNext = () => {
        // 마지막 요소일 때는 더 이상 증가하지 않음
        if (currentComponent < component.length - 1) {
            setCurrentComponent((prevIndex) => prevIndex + 1);
        }
    };

    return (
        <div className="flex w-full flex-row items-center justify-center">
            {currentComponent === 0 ? null : (
                <img
                    src={forward}
                    style={{
                        width: '45px',
                        height: '44px',
                        rotate: '180deg',
                        shapeOutside: 'inherit',
                        filter: 'drop-shadow(-3px -3px 2px rgba(0, 0, 0, 0.3))',
                    }}
                    onClick={handlePrev}
                />
            )}

            <div className="flex flex-col w-982px px-5">
                <Typography
                    paddingBottom="15px"
                    fontSize="36px"
                    fontWeight="700"
                    textAlign="left"
                >
                    시작하기 전에
                </Typography>

                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '982px',
                    }}
                />
                <div className="pb-5" />

                {component[currentComponent]}

                <Typography
                    textAlign="right"
                    fontSize="15px"
                    fontWeight="400"
                    paddingRight="20px"
                >
                    ({currentComponent + 1}/{component.length})
                </Typography>
                <div className="pb-5" />
                <Divider
                    style={{
                        border: '1px solid #ABABAB',
                        width: '982px',
                    }}
                />
            </div>

            {currentComponent === 2 ? null : (
                <img
                    src={forward}
                    style={{
                        width: '45px',
                        height: '44px',
                        shapeOutside: 'inherit',
                        filter: 'drop-shadow(-3px 3px 2px rgba(0, 0, 0, 0.3))',
                    }}
                    onClick={handleNext}
                />
            )}
        </div>
    );
};
export default Help;
